import { Card, CardContent, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { messages } from 'constants/messages'
import { SXObject } from 'modules/techbots/components/techBotDetails/constants'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { useState, useEffect } from 'react'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'

const LiveSearch = () => {
  const { techBotDataSources } = messages.techbots
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = data?.id || ''

  const { data: bot } = api.useGetBotByIdQuery(
    { id: botId as string },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [updateBotById] = api.useUpdateBotByIdMutation()

  const [includeGrounding, setIncludeGrounding] = useState(bot?.include_grounding)

  useEffect(() => {
    if (bot?.include_grounding !== undefined) {
      setIncludeGrounding(bot.include_grounding)
    }
  }, [bot?.include_grounding])

  const handleIncludeGrounding = async (checked: boolean) => {
    try {
      setIncludeGrounding(checked)

      await updateBotById({ id: botId, body: { include_grounding: checked } }).unwrap()
      bot?.include_grounding
        ? toastFuncNotification(techBotDataSources.liveSearchOff)
        : toastFuncNotification(techBotDataSources.liveSearchOn)
    } catch {
      toastFuncNotification(techBotDataSources.liveSearchOff)
    }
  }

  return (
    <Box>
      <Card
        sx={{
          maxWidth: 416,
          padding: '16px 16px 0px',
          borderRadius: '12px',
          boxShadow: 'none',
          border: '1px solid #DCDCDC',
        }}
      >
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '40px', padding: '0 !important' }}>
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '22px',
                fontFamily: 'Inter',
                letterSpacing: '-0.5px',
                color: '#353535',
              }}
            >
              {techBotDataSources.liveSearchTitle}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
                color: '#777777',
                fontFamily: 'Inter',
                letterSpacing: '-0.5px',
              }}
            >
              {techBotDataSources.liveSearchDescription}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative', left: '-12px' }}>
            <Switch
              checked={includeGrounding}
              sx={SXObject}
              onChange={async (e, checked) => handleIncludeGrounding(checked)}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default LiveSearch
