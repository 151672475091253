import { FC, useState, CSSProperties } from 'react'
import styles from './CustomInput.module.scss'
import IconEyeHide from 'assets/icons/iconEyeHide'
import IconEyeShow from 'assets/icons/iconEyeShow'
import { KEY_ENTER } from 'utils/constant'
import IconChevronDown from 'assets/icons/iconChevronDown'

interface iCustomInput {
  id?: string
  value: string
  label?: string
  labelVisible?: boolean
  multiline?: boolean
  placeholder: string
  onChangeValue?: (value: string) => void
  onChangeSelect?: ({ label, value }: { label: string; value: string }) => void
  textError?: string
  isInvalid?: boolean
  type?: string
  isRequired?: boolean
  requiredCustomMessage?: string
  fullWidth?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onPressEnter?: () => void
  disabled?: boolean
  selectOptions?: { [key: string]: any }
  disableAutoComplete?: boolean
  characterLimit?: number
  inputSuffix?: string
  style?: CSSProperties
  styleLabel?: CSSProperties
  styleTextarea?: CSSProperties
  styleRequired?: CSSProperties
}

const {
  inputContainer,
  fullContainer,
  inputStyles,
  labelStyles,
  labelFilled,
  inputError,
  labelError,
  errorTextStyles,
  inputWithIcon,
  infoTextStyles,
  labelVisibleStyles,
  textareaStyles,
  disabledLabel,
  selectStyles,
  chevronDown,
  inputSuffixContainer,
} = styles

const CustomInput: FC<iCustomInput> = ({
  id,
  value,
  placeholder,
  labelVisible = false,
  multiline = false,
  label,
  textError,
  isInvalid,
  type = 'text',
  isRequired,
  requiredCustomMessage,
  fullWidth = false,
  onBlur,
  onFocus,
  onPressEnter,
  disabled = false,
  selectOptions,
  onChangeValue = () => {},
  onChangeSelect = () => {},
  disableAutoComplete,
  characterLimit,
  inputSuffix,
  style,
  styleLabel,
  styleTextarea,
  styleRequired,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const onHandleValue = (e: any): void => {
    const targetValue = e?.target?.value
    if (type === 'select' && e.target?.options?.length > 0 && e.target.options[e?.target?.selectedIndex]) {
      const label = e.target.options[e.target.selectedIndex].text
      onChangeSelect({ label, value: targetValue })
      return
    }
    onChangeValue(targetValue)
  }

  const onHandleBlur = (): void => {
    onBlur?.()
  }

  const onHandleFocus = (): void => {
    onFocus?.()
  }

  const toggleEyeIcon = (): void => setIsPasswordVisible((prevState) => !prevState)

  const renderEyeIcon = (): JSX.Element => {
    const { iconEyeStyles } = styles
    return (
      <div onClick={toggleEyeIcon} className={iconEyeStyles}>
        {isPasswordVisible ? <IconEyeShow /> : <IconEyeHide />}
      </div>
    )
  }

  const onHandleKeyPress = (e: any): void => {
    if (e.keyCode === KEY_ENTER && !isMultiline) {
      e.preventDefault()
      onPressEnter?.()
    }
  }

  const isErrorVisible = typeof isInvalid === 'boolean' && isInvalid
  const isSecuredInput = type === 'password'
  const isRequiredField = typeof isRequired === 'boolean' && isRequired

  const labelValue = typeof label === 'string' ? label : placeholder
  const isLabelVisible = typeof labelVisible === 'boolean' && labelVisible

  const isMultiline = typeof multiline === 'boolean' && multiline

  let options = {}

  let InputComponent: any = 'input'

  const selectCollection = Object.entries(selectOptions || {})

  if (type === 'select' && selectCollection.length > 0) {
    return (
      <div
        className={`${inputContainer} ${fullWidth ? fullContainer : ''} 
      `}
      >
        <label
          className={`${labelStyles} ${value !== '' ? labelFilled : ''} ${
            isLabelVisible ? labelVisibleStyles : ''
          } ${isErrorVisible ? labelError : ''} ${disabled ? disabledLabel : ''}`}
          htmlFor={id}
        >
          {labelValue}
        </label>
        <select
          id={id}
          value={value}
          onChange={onHandleValue}
          onBlur={onHandleBlur}
          className={`${selectStyles} ${inputStyles} ${
            isErrorVisible ? inputError : ''
          } ${isSecuredInput ? inputWithIcon : ''}`}
        >
          {selectCollection.map(([group, keys]) => (
            <optgroup key={group} label={group}>
              {keys.map((record: { value: string; label: string }) => (
                <option key={record.value} value={record.value}>
                  {record.label}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        <IconChevronDown className={chevronDown} />
      </div>
    )
  }

  if (isMultiline) {
    InputComponent = 'textarea'
    options = {
      rows: 2,
    }
  } else {
    options = {
      type: isPasswordVisible ? 'text' : type,
    }
  }
  return (
    <div
      className={`${inputContainer} ${fullWidth ? fullContainer : ''} ${isMultiline ? textareaStyles : ''}`}
      style={style}
    >
      <label
        className={`${labelStyles} ${value !== '' ? labelFilled : ''} ${
          isLabelVisible ? labelVisibleStyles : ''
        } ${isErrorVisible ? labelError : ''} ${disabled ? disabledLabel : ''}`}
        htmlFor={id}
        style={styleLabel}
      >
        {labelValue}
      </label>
      <InputComponent
        id={id}
        className={`${inputStyles} ${isErrorVisible ? inputError : ''} ${isSecuredInput ? inputWithIcon : ''}`}
        value={value}
        onChange={onHandleValue}
        onFocus={onHandleFocus}
        onBlur={onHandleBlur}
        onKeyDown={onHandleKeyPress}
        placeholder={isLabelVisible ? placeholder : ''}
        disabled={disabled}
        autoComplete={disableAutoComplete ? 'new-password' : 'on'}
        maxLength={characterLimit}
        {...options}
        style={styleTextarea}
      />
      {inputSuffix && <span className={inputSuffixContainer}>{inputSuffix}</span>}
      {isSecuredInput && renderEyeIcon()}
      {isErrorVisible && <span className={errorTextStyles}>{textError}</span>}
      {isRequiredField && !isErrorVisible && (
        <span className={infoTextStyles} style={styleRequired}>
          {requiredCustomMessage ? requiredCustomMessage : '*Required'}
        </span>
      )}
    </div>
  )
}

export default CustomInput
