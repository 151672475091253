import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'
import { Typography, Box } from '@mui/material'
import CustomInput from 'components/customInput/CustomInput'
import { schema } from './schema'
import { InferType } from 'yup'
import { messages } from 'constants/messages'

type FormData = InferType<typeof schema>

interface InstructionFieldProps {
  name: keyof FormData
  control: Control<FormData>
  errors: FieldErrors<FormData>
  setValue: (name: keyof FormData, value: string) => void
  trigger: (name: keyof FormData) => Promise<boolean>
}

const InstructionField: FC<InstructionFieldProps> = ({ name, control, errors, setValue, trigger }) => {
  const { instructions: instructionsMessages } = messages

  const titles = {
    mission: instructionsMessages.missionTitle,
    coreInstructions: instructionsMessages.coreTitle,
    guardrails: instructionsMessages.guardrailsTitle,
    extras: instructionsMessages.extrasTitle,
    dataGathering: instructionsMessages.dataGatheringFieldTitle,
  }

  const descriptions = {
    mission: instructionsMessages.missionDescription,
    coreInstructions: instructionsMessages.coreDescription,
    guardrails: instructionsMessages.guardrailsDescription,
    extras: instructionsMessages.extrasDescription,
    dataGathering: instructionsMessages.dataGatheringFieldDescription,
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box sx={{ padding: '0px 16px' }}>
        <Typography
          sx={{
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: '600',
            color: '#191919',
          }}
        >
          {titles[name]}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Manrope',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '22px',
            color: '#777777',
          }}
        >
          {descriptions[name]}
        </Typography>
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomInput
            id={name}
            value={field.value || ''}
            onChangeValue={(val) => {
              setValue(name, val)
              trigger(name)
            }}
            placeholder={titles[name]}
            isInvalid={!!errors[name]}
            onBlur={() => trigger(name)}
            multiline
            fullWidth
            style={{ width: '100%', maxWidth: '708px', height: '260px', marginBottom: '0px' }}
            styleLabel={{ display: 'none' }}
            styleTextarea={{
              padding: '16px 16px',
              color: '#353535',
              fontFamily: 'Manrope',
              letterSpacing: '0.2px',
              height: '260px',
              verticalAlign: 'top',
            }}
          />
        )}
      />
    </Box>
  )
}

export default InstructionField
