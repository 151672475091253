import { useState } from 'react'
import styles from './AddContentModal.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconAdd from 'assets/icons/iconAdd'
import CustomInput from 'components/customInput/CustomInput'
import { SXObject } from 'modules/techbots/components/techBotDetails/constants'
import { Switch } from '@mui/material'
import { messages } from 'constants/messages'
import { KnowledgeBody } from 'types/api'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'

const {
  container,
  contentStyles,
  titleStyles,
  messageStyles,
  buttonsGroup,
  addContentInput,
  addContentTextarea,
  statusContainer,
  statusContent,
  statusContentTitle,
  statusContentDescription,
} = styles

const { addContentModal } = messages.knowledgeMessages

type AddContentModalProps = {
  isVisible: boolean
  title: string
  message: string
  onContinue: (content: KnowledgeBody) => void
  onClose: () => void
}

const AddContentModal = ({ isVisible = true, title, message, onContinue, onClose }: AddContentModalProps) => {
  const [newContent, setNewContent] = useState({
    title: '',
    description: '',
    disabled: false,
  })

  const user = useAppSelector((state: ReduxState) => state.user)

  const handleTitleChange = (value: string) => {
    setNewContent({ ...newContent, title: value })
  }

  const handleDescriptionChange = (value: string) => {
    setNewContent({ ...newContent, description: value })
  }

  const handleAddContent = () => {
    onContinue(newContent)
    setNewContent({ title: '', description: '', disabled: false })
    onClose()
  }

  return isVisible ? (
    <div className={container}>
      <div className={contentStyles}>
        <div className={titleStyles}>{title}</div>
        <div className={messageStyles}>{message}</div>
        <div className={addContentInput}>
          <CustomInput
            label={addContentModal.title}
            labelVisible={true}
            placeholder={addContentModal.addTitle}
            value={newContent.title}
            onChangeValue={handleTitleChange}
          />
        </div>
        <div className={addContentTextarea}>
          <CustomInput
            label={addContentModal.content}
            labelVisible={true}
            placeholder={addContentModal.typeContent}
            value={newContent.description}
            onChangeValue={handleDescriptionChange}
            multiline
          />
        </div>
        <div className={statusContainer}>
          <div className={statusContent}>
            <div className={statusContentTitle}>{addContentModal.status}</div>
            <div className={statusContentDescription}>
              {addContentModal.statusDescription.replace('{company_name}', user.company_name)}
            </div>
          </div>
          <Switch
            checked={newContent.disabled}
            sx={SXObject}
            onClick={() => setNewContent({ ...newContent, disabled: !newContent.disabled })}
          />
        </div>

        <div className={buttonsGroup}>
          <ActionButton
            label={addContentModal.cancel}
            onPress={onClose}
            buttonType={ActionButtonType.TRANSPARENT}
            size={ActionButtonSize.MEDIUM}
          />
          <ActionButton
            label={addContentModal.add}
            onPress={handleAddContent}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            icon={<IconAdd />}
            disabled={newContent.description === '' || newContent.title === ''}
          />
        </div>
      </div>
    </div>
  ) : null
}

export default AddContentModal
