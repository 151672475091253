export const messages = {
  common: {
    somethingWentWrong: 'Something went wrong. Please try again later.',
  },
  auth: {
    invitation: {
      step: 'Personal info',
      header: {
        title: 'Complete the sign up to join {companyName} workspace.',
        subtitle: 'Setting account for {email}',
      },
      form: {
        name: 'Your Name',
        nameError: 'Name name can`t be empty',
        password: 'Password',
        action: 'Sign Up',
      },
      info: {
        success: 'User was successfully created',
        failed: 'Something went wrong, please try again.',
      },
    },
    resizeScreenMessage: 'Please resize your screen.',
  },
  conversations: {
    chooseConversation: 'Choose conversation to start.',
    conversationDetails: {
      title: 'Conversation Details',
      id: 'ID',
      phone: 'Customer Phone',
      email: 'Customer Email',
      source: 'Source',
      conversationData: 'Conversation Data',
      conversationDataDescription: 'This conversation collected set data.',
      conversationSummary: 'Conversation Summary',
      customerSentiment: 'Customer Sentiment',
      problemSummary: 'Problem Summary',
      status: 'Status',
    },
    errors: {
      endConversation: 'Failed to end conversation, please try again.',
    },
  },
  dashboard: {
    pageHeaderTitle: 'Dashboard',
    overview: {
      title: 'Performance Overview',
      selectDateRange: {
        label: 'Select Date Range',
        fromLabel: 'From:',
        toLabel: 'To:',
        cancelButton: 'Cancel',
        applyButton: 'Done',
      },
    },
    summaryCards: {
      gratified: {
        title: 'SupportX Performance',
        total: 'Total Conversations',
        aiConversations: 'AI Conversations',
        aiResolutionRate: 'Solved by AI',
        ongoing: 'Ongoing',
      },
      chat: {
        title: 'Agent Performance',
        agentConversations: 'Agent Conversations',
        pushedToAgent: 'Pushed to Agent',
        solvedByAgent: 'Solved by Agent',
        unresolved: 'Unresolved',
      },
    },
    conversations: 'Conversations',
    chart: {
      numberOfConversations: 'Number of Conversations',
      noData: 'No data for selected period',
      agentTooltip: 'Agent Conversations',
      gptTooltip: 'AI Conversations',
    },
    lastConversations: {
      title: 'Last Conversations',
      showConversation: 'Show Conversations',
      noData: 'No data for selected period',
    },
    userSentiment: {
      title: 'Customer Satisfaction',
      description: 'We use AI to calculate customer satisfaction.',
      noData: 'No data for selected period',
    },
    conversationByStatus: {
      title: 'Conversations by Status',
      description:
        'Ongoing conversations are conversations moved to an Agent. We determine if the case was resolved by using AI to analyze the conversation.',
      noData: 'No data for selected period',
    },
  },
  demoPage: {
    demoTestChat: '{company_name} TEST CHAT',
    testYourWidget: 'Test Your SupportX Assistant',
    demoTestDescription: 'This is the {company_name} Assistant. Ask me anything about our products or services.',
  },
  forgotPasswordScreen: {
    title: 'Forgot your password?',
    subtitle: 'Enter your email address and we will send you a link to create a new password.',
    instructionSent: 'Instructions sent to {userEmail}',
    instructionSentDescription:
      "If you have a SupportX account you will receive a password reset link. Don't see it? Check your spam folder.",
    emailPlaceholder: 'Work email',
    emailErrorText: 'This email is incorrect or empty',
    return: 'Return to login',
    send: 'Send',
  },
  handoff: {
    noteTitle: 'Handoff Settings',
    noteDescription:
      'Choose what happens when your customers need to reach human support. Settings are global for all your teammates.',
    emailPlaceholder: 'Email Address',
    emailError: 'You cannot leave email empty.',
    emailInvalid: 'Email is not valid',
    liveChat: 'Live Chat Mode',
    liveChatDescription:
      "When agents are available, a live conversation will begin. If the agent doesn't respond in a timely manner, the conversation will be converted into a support ticket.",
    inbox: 'Ticket Mode',
    inboxDescription: 'When customers request human assistance, a new support ticket is created in SupportX.',
    toMail: 'Handoff to Email',
    toMailDescription:
      'When customers request human assistance, a new support ticket is created by sending an email to a company support address',
    toZendesk: 'Handoff to Zendesk',
    toZendeskDescription: 'Create a tickets of SupportX contact requests in Zendesk.',
    connectZendesk: 'Integrate with Zendesk',
    zendeskConnected: 'Connected to Zendesk',
    handoffSaved: 'Handoff settings saved successfully',
    handoffError: 'Handoff settings update error',
  },
  integrations: {
    header: 'Integrations',
    title: 'SupportX Integrations',
    description: 'SupportX can integrate with the software of your choice. More integrations coming soon.',
    googleDrive: {
      title: 'Google Drive',
      description:
        'Connect with your google drive to choose files and folders you want to include in your data sources.',
      connectGoogleDrive: 'Connect Google Drive',
      steps: 'Step 1 of 3',
      aboutTheIntegration: 'About the integration',
      descriptionAboutTheIntegration:
        'SupportX allow to connect with your Google Drive. you will be able to import chosen files and folders to include them in your model.',
      chooseFolder: 'Choose folder to synchronize',
      tip: 'Tip: You can synchronize one folder, but the folder can have multiple files and folders.',
      googleDriveSynced: 'Google Drive synced successfully',
      buttons: {
        cancel: 'Cancel',
        download: 'Download Selected Folder',
        connect: 'Connect Google Drive',
        sync: 'Sync Google Drive',
        list: 'List folders Google Drive',
      },
    },
    hubspot: {
      title: 'Hubspot',
      description: 'Hubspot integration allows you to handoff conversations as a tickets in hubspot.',
    },
    freshdesk: {
      title: 'Freshdesk',
      description: 'Freshdesk integration allows you to handoff conversations as a tickets in hubspot.',
    },
    zendesk: {
      title: 'Zendesk',
      description:
        'Zendesk integration allows you to download conversations from zendesk that will fed your data sources.',
      stepOne: {
        title: 'Zendesk Integration',
        step: 'Step 1 of 3',
        about: 'About the integration',
        description: 'SupportX can integrate with Zendesk to automatically learn from support tickets.',
        install: 'Install Zendesk',
      },
      stepTwo: {
        connect: 'Connect to Zendesk',
        step: 'Step 2 of 3',
        benefitDescription: 'The following content from Zendesk will be used:',
        benefit: 'Zendesk Chat Transcriptions',
        authInstructionTitle: 'How to connect',
        authInstructionList:
          "<ol><li>1. Give SupportX <a href='https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API' target='_blank'>access to Zendesk</a>.</li><li>2. Obtain your Zendesk subdomain, username and API key</li><li>3. Click on 'New Authentication' below.</li></ol>",
        back: 'Back',
        newAuthentication: 'New Authentication',
      },
      stepThree: {
        integrationSuccess: 'Successfully connected with Zendesk.',
        createAuthentication: 'Create New Authentication',
        step: 'Step 3 of 3',
        subdomain: 'Zendesk Subdomain',
        subdomainSuffix: '.zendesk.com',
        subdomainError: 'Should be in a form of "subdomain" not "https://subdomain.zendesk.com"',
        username: 'Zendesk Username',
        key: 'Zendesk Key',
        back: 'Back',
        newAuthentication: 'New Authentication',
      },
    },
  },
  knowledgeMessages: {
    title: 'Knowledge Generation',
    entries: 'Entries',
    addContent: 'Add New Content',
    review: 'Review Suggestions',
    noteSupportXDescription:
      "Review knowledge automatically suggested by supportX or add your own. <br/> New knowledge will be automatically used by supportX's AI when responding to users",
    noteZendeskDescription: 'Suggestions from Zendesk are made of your Zendesk conversations with customers.',
    preview: 'Preview',
    suggested: 'Suggested By',
    addedDate: 'Added Date',
    status: 'Status',
    tooltipContent: 'Status indicates if supportX will consider this knowledge entry when formulating responses.',
    editedTime: 'Last Edited',
    item: {
      title: 'Title:',
      content: 'Content:',
    },
    active: 'Active',
    inactive: 'Inactive',
    context: {
      edit: 'Edit Content',
      delete: 'Delete Content',
    },
    emptyKnowledge:
      'Nothing here. When you review suggestions from supportX or upload your own knowledge, it will show up here.',
    addContentSuccessToast: 'Content Added to {company_name} knowledge base. Updating changes can take up to one hour.',
    editContentSuccessToast: 'Content changed successfully. Updating changes to model can take up to one hour.',
    deleteSuccessToast: 'Content removed successfully. It will be soon removed from the knowledge base.',
    errorToast: 'Something went wrong. Please try again later.',
    addContentModal: {
      title: 'Title',
      addTitle: 'Add Title',
      content: 'Content',
      typeContent: 'Type Content',
      status: 'Status',
      statusDescription: 'Use this content for {company_name}',
      cancel: 'Cancel',
      add: 'Add New Content',
      message:
        "Add content to supportX's knowledge base. This content will be considered when using AI to respond to your users. ",
    },
    editContentModal: {
      addTitle: 'Edit Content',
      typeContent: 'Type Content',
      cancel: 'Cancel',
      save: 'Save Changes',
      message: 'Edit new knowledge. Save Changes so new knowledge will be used by supportX.',
      dialogModal: {
        title: 'Are you sure?',
        message: 'Save changes before leaving to make sure your content is updated',
        cancelButton: 'Leave Without Saving',
        continueButton: 'Save Changes and Leave',
      },
    },
    deleteModal: {
      title: 'Are you sure?',
      message: "Removing this entry deletes this knowledge from supportX's knowledge base.",
      cancel: 'Cancel',
      delete: 'Delete Content',
    },
    reviewModal: {
      review: 'Review Content',
      reviewedAll: 'You have reviewed all suggestions.',
      buttons: {
        edit: 'Edit',
        reject: 'Reject Content',
        close: 'Close',
      },
    },
  },
  loginScreen: {
    welcomeBack: 'Welcome back',
    loginToAccount: 'Log in to your account.',
    welcomeBackWithName: 'Hello {name}. Welcome back.',
    emailPlaceholder: 'Work email',
    emailErrorText: 'This email is incorrect or empty',
    passwordPlaceholder: 'Password',
    passwordErrorText: "Password can't be empty",
    forgotPassword: 'Forgot your password? Click',
    forgotPasswordHere: ' here.',
    login: 'Log In',
  },
  menu: {
    dashboard: 'Dashboard',
    dataGathered: 'Data Gathered',
    conversations: 'Conversations',
    customize: 'Customize',
    techbots: 'Training',
    knowledge: 'Knowledge Generation',
    settings: 'Settings',
    logout: 'Log Out',
    subscription: 'Subscription',
    account: 'Account',
    helpCenter: 'Help Center',
    teamManagement: 'Team Management',
  },
  portal: {
    portalPreview: {
      title: 'Your SupportX Help Center is {status}.',
      description:
        'Your new SupportX Help Center is available. You can test it and link it to your website when ready.',
      linkPlaceholder: 'Link to SupportX Help Center',
      openInNewTab: 'Open in New Tab',
      authProtection: 'Password Protected Access',
      authProtectionDescription:
        'Limit Access to portal for invited teammates only if you want your portal to be restricted for public.',
      noteTitle: 'Note:',
      noteDescription:
        'Turn on the password protection to create allowed list of the users that can enter portal. To make it public, just switch it off.',
      saveChangesSuccess: 'Changes saved successfully',
      saveDataError: 'Failed to save supportX data',
      manageAccessToPortal: 'Manage Access to Portal',
      usersCanAccessThePortal: 'Users can access the portal',
      selectIndividualEmail:
        'Select individual email addresses that can enter the portal when it’s password protected.',
      manageAccess: 'Manage Access',
    },
    viewersModal: {
      managePortalAccess: 'Manage Portal Access',
      restricted: 'When portal is restricted, those are users that can access the portal when it’s password protected.',
      users: 'users',
      email: 'Email',
      invitationStatus: 'Invitation Status',
      removeInvitation: 'Remove Invitation',
      noViewers: 'No portal viewers available',
      invitationRemovedFor: 'Invitation removed for',
      invitationDoNotRemoved: 'Invitation do not removed',
      invitePortalUsers: 'Invite Portal Users',
      pendingInvite: 'Pending Invite',
      viewer: 'Viewer',
      done: 'Done',
      inviteYourTeam:
        'Invite your team members so they can help you answer customer requests, track data and handle support issues.',
      inviteUsers: 'Invite Users to {companyName} Portal',
    },
    portalCustomization: {
      introduceYourBrand: '1. Introduce Your Brand',
      uploadWebsiteLogo: 'Upload Logo',
      uploadWebsiteLogoDescription:
        'The brand logo will be displayed in the left top corner of your help center. We suggest using your primary brand color to keep consistency.',
      addPrimaryBrandColors: 'Add Brand Color',
      addPrimaryBrandColorDescription: 'Reflect your brand with your brand color.',
      customMessages: '2. Custom Messages',
      noteTitle: 'Custom Messages',
      noteDescription:
        'Custom messages helps you properly introduce your customers to your help center. Changes will reflect on the help center preview on your right instantly.',
      initialMessage: 'Initial Message*',
      initialMessagePlaceholder: 'Welcome message that user sees after opening.',
      initialMessageError: "Initial message can't be empty",
      subMessage: 'Sub Message*',
      subMessagePlaceholder: 'Message Subheading',
      subMessageError: "Sub message can't be empty",
      saveChangesSuccess: 'Changes saved successfully',
      uploadLogoSuccess: 'Logo uploaded successfully',
      uploadMessageBadgeSuccess: 'Message Badge uploaded successfully',
      uploadFailed: 'Failed to upload file',
      authProtection: 'Auth Protection',
      authProtectionDescription:
        'Enable Auth Protection to restrict access to your portal to only authenticated users from your company.',
    },
  },
  instructions: {
    changeInstructionsToast: 'Model instructions change to custom.',
    changeDataGatheringToast: 'Data Gathering has been changed.',
    changeToDefaultToast: 'Model instructions changed to default.',
    noteTitle: 'Note:',
    noteDescription:
      'Set up your custom instructions in order to change the behavior of the portal and widget. By putting your own instructions you can create better behavior, adjust individual paths and industry patterns to the AI model.',
    customTitle: 'Custom Instructions',
    customDescription:
      'Use custom instructions to adjust SupportX Model for your personalized needs. If disabled, the system will use default instructions.',
    dataGathering: 'Data Gathering',
    dataGatheringDescription: 'Gather data for any purposes.',
    missionTitle: 'Mission',
    missionDescription: 'Define Mission',
    coreTitle: 'Core Instructions',
    coreDescription: 'Define Core Instructions',
    guardrailsTitle: 'Guardrails',
    guardrailsDescription: 'Define Guardrails',
    extrasTitle: 'Extras',
    extrasDescription: 'Define Extras',
    dataGatheringFieldTitle: 'Data Instructions',
    dataGatheringFieldDescription: 'Give exact instruction to gather data.',
    deleteConversationSuccess: 'Deletion was successful.',
    deleteWithErrors:
      'Deletion completed with some errors. {successfulDeletes} file(s) deleted, {failedDeletes} file(s) failed.',
    saveChanges: 'Save your changes',
    saveChangesDescription: 'Save changes before leaving. Otherwise the changes won’t be applied to AI model.',
    saveChangesButton: 'Save Changes',
    leaveButton: 'Leave without saving',
  },
  profile: {
    title: 'My Profile',
    nameLabel: 'Your Name',
    emailLabel: 'Email',
    timezoneLabel: 'Timezone',
    matchPasswordErrorText: 'Passwords need to match',
    removePhotoToastInfo: 'Photo removed successfully',
    uploadPhotoToastInfo: 'Photo changed successfully',
    nameErrorText: 'Name is required',
    companyErrorText: 'Workspace name is required',
    companyNameToastInfo: 'Workspace name changed successfully',
    changedNameToastInfo: 'Name changed successfully',
    changedTimezoneToastInfo: 'Timezone changed successfully',
    changedPasswordToastInfo: 'Password changed successfully',
    actionBoxChangePassword: {
      title: 'Password',
      subtitle: 'Change the password for your account.',
      buttonText: 'Change Password',
    },
    actionBoxRemoveAccount: {
      title: 'Remove Account',
      subtitle: 'Remove your account from supportX.',
      buttonText: 'Delete Account',
    },
    formModalChangePassword: {
      title: 'Change Password',
      message: 'To change your password please enter your current password.',
      buttonText: 'Change Password',
      inputs: {
        currentPassword: {
          label: 'Current Password',
          placeholder: 'Enter your current password',
          errorText: 'Password is incorrect',
          passwordTooShortErrorText: 'Password is too short',
        },
        newPassword: {
          label: 'New Password',
          placeholder: 'Enter your new password',
        },
        repeatNewPassword: {
          label: 'Repeat New Password',
          placeholder: 'Repeat your new password',
        },
      },
    },
    formModalRemoveAccount: {
      title: 'Remove Account',
      message: 'Your account will be removed along with all your data.',
      buttonText: 'Remove Account',
      inputs: {
        currentPassword: {
          label: 'Current Password',
          placeholder: 'Current Password',
          errorText: 'Password is incorrect',
        },
        repeatPassword: {
          label: 'Repeat Current Password',
          placeholder: 'Repeat Current Password',
        },
      },
    },
    uploadAvatarInput: {
      allowedFormats: 'Allowed formats: PNG, JPEG, up to 4mb',
      title: 'Upload your photo',
      subtitle: 'Your photo will be displayed in customer chat.',
      uploadLabel: 'Upload Photo',
      removeLabel: 'Remove Photo',
      fileToastError: 'File is to big. Please upload smaller file.',
    },
  },
  techbots: {
    setup: {
      header: 'SupportX Setup',
      saveChanges: 'Save Changes',
    },
    techbotCreate: {
      notFound: 'SupportX training data not found',
      dialogModal: {
        title: 'Are you sure?',
        message:
          'If you cancel the supportX training process your changes won’t be saved. You can save a draft and continue later.',
        cancelButton: 'Cancel',
        continueButton: 'Quit Training',
      },
    },
    filesMessages: {
      fileName: 'File Name',
      addedBy: 'Added by',
      addedDate: 'Added Date',
      addedByAgent: 'Agent',
      foundOnWebsite: 'From Websites',
    },
    contentForBrandInfo: {
      offlineMessagePlaceholder:
        'Sorry. Our support chat is down for a moment. If you want to contact us - don’t hesitate to call 415-555-1212 or visit http://company.com/contact',
      welcomeMessagePlaceholder: 'Hi! Welcome to our 24/7 support center.',
      questionMessage: 'My product is not functioning correctly. Can you help me?',
      time: '9:00',
      sendRequest: 'Send Request',
      email: 'user@company.com',
    },
    contentForDataSources: {
      messageContent:
        "SupportX's AI relies on data sources you train it with. Upload any site or document that can help supportX respond to your customers.",
    },
    inputForBrandInfo: {
      typeMessage: 'Type message',
    },
    widgetMessage: {
      widgetMessagePlaceholder: 'Chat message will show up here',
    },
    botPreview: {
      companyName: 'Company',
      avaliability: 'Use our 24/7 AI Chat',
    },
    colorPicker: {
      selectColor: 'Select Color',
      subinfo: 'The color will be visible on your chat messages and chat label to highlight your brand',
    },
    firstTechBotInfo: {
      title: 'Train supportX with your own information',
      description:
        'SupportX is designed to automatically respond to your customers and loop in your support agents when extra help is needed. <br /><br /> There are three steps needed to configure supportX:',
      bulletFirst:
        'supportX is pre-trained for your company. Upload additional knowledge sources and customize Your Help Center and Assistant.',
      bulletSecond: 'Try out supportX with your teammates and colleagues.',
      bulletThird: 'Deploy supportX commercially and monitor its performance.',
      buttonLabel: 'Train supportX',
      errorToast: 'Failed to train supportX',
    },
    implementTechbotInfo: {
      title: 'Implement supportX',
      description: 'Finish setup of supportX and start giving your customers the ultimate support experience.',
      buttonLabel: 'Implement supportX',
    },
    buildingTechbotInfo: {
      title: 'We are training supportX',
      description: 'Your training will be completed shortly. We will let you know when supportX is ready.',
    },
    techBotBrandForm: {
      title: 'Introduce your brand',
      saveDraftButton: 'Save as Draft',
      saveChangesButton: 'Save Changes',
      nameYourBot: 'Name your SupportX Assistant',
      widgetName: 'Assistant Name',
      widgetNamePlaceholder: 'Enter Assistant Name',
      widgetNameError: "Assistant name can't be empty",
      companyWebsite: 'Company Website',
      companyWebsitePlaceholder: 'Enter Company Website',
      companyWebsiteError: 'Company website is invalid',
      companyWebsiteCustomMessage: 'Website that widget is restricted to. To change it, contact us.',
      uploadLogo: 'Upload Logo',
      addPrimaryBrandColors: 'Add Primary Brand Colors',
      addSecondaryBrandColors: 'Add Secondary Brand Colors',
      chatMessages: 'Chat Messages',
      widgetMessage: 'Assistant Message*',
      widgetMessagePlaceholder: 'Message will show up once the Assistant is ready to use',
      widgetMessageError: "Welcome message can't be empty",
      welcomeMessage: 'Welcome Message*',
      welcomeMessagePlaceholder: 'Message Your customers will see when they open the Assistant.',
      welcomeMessageError: "Welcome message can't be empty",
      offlineMessage: 'Offline Message*',
      offlineMessagePlaceholder: 'Hi! Welcome to our support assistant',
      offlineMessageError: "Offline message can't be empty",
      next: 'Next',
    },
    techBotBuild: {
      title: 'Training supportX',
      description:
        'SupportX is being trained on your custom knowledge. This process usually takes 30-60 minutes. We will send you an email when supportX is ready, or you can stay on this page. (You don’t have to).',
      fakeInformation: 'Step 1: Gathering Information',
    },
    techBotCard: {
      remove: 'Remove',
    },
    techBotDataSources: {
      headerTitle: 'Add Data Resources',
      backButton: 'Back',
      editCompanyDetails: 'Edit Company Details',
      backToTest: 'Back To Test',
      close: 'Close',
      saveDraftButton: 'Save as Draft',
      saveChangesButton: 'Save Changes',
      websites: 'Websites',
      links: 'Links',
      includePdf: 'Include PDF’s',
      tooltip: 'SupportX will include PDF’s from crawled website. This will increase time for crawling a website.',
      link: 'Link',
      pagesCrawled: 'Pages Crawled',
      imagesWebsite: 'Images',
      pdf: "PDF's",
      lastUpdate: 'Last Update',
      updateSource: 'Update Source',
      addWebsites: 'Add Websites',
      addLink: 'Add Website',
      enterUrl: 'Enter the URL',
      urlPlaceholder: 'e.g. https://www.yourcompany.com/faq',
      urls: 'URLs',
      updateButton: 'Update',
      updatingStatus: 'Updating',
      failedStatus: 'Failed',
      noWebsites: 'No added websites',
      addFiles: 'Add Files',
      uploadFiles: 'Upload Files',
      files: 'Files',
      zendesk: 'Zendesk',
      selfLearning: 'Self Learning',
      snippets: 'Snippets',
      images: 'Images',
      added: 'added',
      crawled: 'crawled',
      synced: 'synced',
      ofAddedWwebsites: 'of {added} websites',
      buildTechbot: 'Train supportX',
      dataSourcesSummaryTitle: 'Your SupportX tools are built of data sources',
      dataSourcesSummaryDescription:
        'Based on crawled informations about your company, SupportX pre-builds your company knowledge base. Add additional files and websites that will expend your knowledge base.',
      errorToast: 'Something went wrong. Please try again later.',
      syncingSuccess: 'Data synced successfully',
      zendeskSyncSuccessToast: 'Zendesk data synced successfully',
      useSupportX: 'SupportX knowledge Generation',
      useSupportXDescription: 'SupportX updates its training data every 24h. You can manually update it below.',
      useZendesk: 'Use Zendesk Tickets',
      useZendeskDescription: 'SupportX will use snippets of knowledge from your Zendesk tickets.',
      syncZendesk: 'Sync Now',
      syncing: 'Syncing',
      lastSync: 'Last Sync:',
      neverSynced: 'Never',
      integrateZendesk: 'Integrate with Zendesk',
      uploadFileSuccess: "You've successfully uploaded new file",
      uploadFileError: 'Failed to upload file, please try again',
      fileRemoved: 'File removed',
      invalidUrl: 'Invalid URL',
      existedUrl: 'URL already exists',
      addLinkSuccess: "You've successfully added new link",
      addLinkError: 'Failed to add link',
      deleteLink: 'Link removed',
      externalDrives: 'External Drives',
      googleDrive: 'Google Drive',
      googleDriveDescription:
        'Connect with your google drive to choose files and folders you want to include in your data sources.',
      syncNow: 'Sync Now',
      connectGoogleDrive: 'Connect Google Drive',
      liveSearch: 'Live Search',
      liveSearchTitle: 'Include Live search results',
      liveSearchDescription: 'Giving SupportX access to live search results can improve knowledge breadth and accuracy',
      liveSearchOn: 'SupportX answers will now use live search results.',
      liveSearchOff: 'SupportX won’t use live search results for answers.',
    },
    techBotDeploy: {
      title: 'Test supportX',
      backToTest: 'Back To Test',
      finish: 'Finish',
      howToInstall: 'How do I install supportX?',
      applyCode: 'In order to make supportX live on your website, use the following code.',
      codeTitle: 'Code',
      copyToClipboard: 'Copy to clipboard',
      sendCodeToEmail: 'Send Code To Email',
      emailPlaceholder: 'Email',
      emailErrorText: 'This email is incorrect or empty',
      noteDescription: 'If you have any issues or questions configuring supportX, please go to our help center.',
      copiedToast: 'Copied to clipboard',
      copyFailedToast: 'Failed to copy to clipboard',
      emailSentToast: 'Code was sent to your email',
    },
    techBotTest: {
      title: 'Test supportX',
      widgetSettings: 'Assistant Settings',
      installGratified: 'Install SupportX on website',
      widgetReady: 'Your Assistant is {status}, you may test it.',
      applyCode: 'In order to install our Assistant on your site you need to follow the configuration procedure.',
      linkToTechbot: 'Link to your trained supportX Assistant',
      copyToClipboard: 'Copy to clipboard',
      openInNewTab: 'Open in New Tab',
      copiedToast: 'Copied to clipboard',
      copyFailedToast: 'Failed to copy to clipboard',
    },
    techBotDetails: {
      title: '{bot_name} support',
      edit: 'Customize supportX',
      activity: 'Chat Activity',
      activityDescription: 'supportX is live and active.',
      offline: 'Offline Mode',
      offlineDescription: 'Select this when you want your support offline for customers.',
      usage: 'Usage',
      usageDescription: 'Conversations this month:',
      openDashboard: 'Open Dashboard',
      dataSources: 'Data Sources',
      editSources: 'Edit Data Sources',
      websites: 'Websites',
      websitesLinked: 'Websites Linked',
      files: 'Files',
      filesAdded: 'Files Added to model',
      knowledge: 'Knowledge Generation',
      entries: 'Entries',
      toReview: 'To Review',
      suggestions: 'Suggestions',
      delete: 'Delete supportX',
      removeBot: {
        title: 'Delete supportX',
        message: 'Your supportX will be removed.',
      },
      setWidgetActivitySuccess: 'Assistant activity changed successfully',
      setWidgetActivityError: 'Failed to change assistant activity status',
    },
    uploadFile: {
      uploadInfo: 'Drop files here or click to upload.',
      uploadSubinfo: 'Supported formats: {formats}. Maximum file size: 500MB.',
      fileToastError: 'File is to big. Please upload a smaller file.',
      fileTypeToastError: 'File format is not supported.',
    },
    uploadLogoInput: {
      allowedFormats: 'Allowed formats: PNG, JPG, up to 4mb',
      uploadLabel: 'Upload a Logo',
      fileToastError: 'File is too big. Please upload a smaller file.',
    },
    dataSourcesStatus: {
      title: 'Training',
      updating: 'Updating',
      updateAvaliable: 'Ready for update',
      upToDate: 'Up to Date',
      lastUpdate: 'Last update:',
    },
  },
  teamManagement: {
    inviteMembers: 'Invite Members',
    roleUpdated: 'Role updated successfully',
    invitationRemoved: 'Invitation to {user_email} removed.',
    userRemoved: '{user_email} removed from {company_name} team.',
    toastError: 'Something went wrong. Please try again later.',
    removeInvitationConfirmation: 'Do you want to remove invitation for {user_email}?',
    removeConfirmation:
      'Removing user from {company_name} workspace would restrict this person from access to {company_name} workspace and Live chat.',
    removeUser: 'Remove User',
    removeInvitation: 'Remove Invitation',
    userItem: {
      pendingInvite: 'Pending Invite',
      removeInvitation: 'Remove Invitation',
      deleteMember: 'Delete Member',
      areYouSure: 'Are you sure?',
      cancel: 'Cancel',
    },
  },
  settings: {
    subscription: {
      buyNewSubscription: {
        title: 'Buy Subscription',
        dialogModal: {
          title: 'Are you sure?',
          message: 'If you cancel the purchase process you will remain in a non-paid tier.',
          cancelButton: 'Cancel',
          continueButton: 'Continue',
        },
        scheduled: 'Subscription has been scheduled successfully',
      },
      checkoutForm: {
        fullName: 'Full Name',
        cardNumber: 'Card Number',
        dateFormat: 'MM/YY',
        cvc: 'CVC',
        subscribe: 'Subscribe to supportX',
        changePlanTo: 'Change Plan to',
        checkout: {
          press: 'If you press',
          subscribe: 'we will charge you',
          today: 'today.',
        },
        agreement: {
          text: "I understand the supportX Privacy Policy and have read and agree to supportX's Terms of Service. MY credit cart will be charged monthly, and fees may vary on usage.",
          required: '*Required',
        },
      },
      newSubscriptionCheckout: {
        addCreditCard: 'Add Credit Card',
        confirmSubscription: 'Confirm Subscription',
        selectedPlan: 'Select Plan',
        setPaymentMethod: 'Set Payment Method',
        dialogModal: {
          title: 'Payment Failed',
          message: 'We failed to charge your credit card. Check the payment details and try again.',
          continueButton: 'Ok',
        },
        success: 'You successfully changed your SupportX plan to {plan_name}.',
      },
      newSubscriptionSuccess: {
        successMessage: 'You Subscribed Successfully',
        startMessage: 'Start using your new plan.',
        setYourFurstBot: 'Set Your First supportX',
      },
      planItem: {
        numberOfConversations: 'SupportX Conversations',
        unique: 'unique',
        deployedBot: 'deployed supportX',
        linkedKnowledgeBase: 'Linked Knowledge Base',
        publicURLs: 'Public URL’s',
        website: 'Website',
        dashboard: 'Dashboard',
        pdfFiles: 'PDF Files',
        historicalSupportTickets: 'Historical Support Tickets',
        choosePlan: 'Choose Plan',
        continuePlan: 'Continue with {plan} Plan',
        resolutionNumber: 'AI Resolutions/Mo Included {price}',
        agentConversations: 'Agent Conversations',
        teamSeats: 'Team/Agent Seats',
        startButton: 'Starts at',
        stayOnPlanButton: 'Stay on Plan',
        contactSalesButton: 'Contact Sales',
        currentPlanButton: 'Current Plan',
      },
      planPicker: {
        monthly: 'Monthly',
        yearly: 'Yearly',
        discount: '20% OFF',
        success:
          'You successfully changed your SupportX plan to Free Plan. It is scheduled to start on the next billing date.',
        error: "Can't downgrade to free subscription, try again later",
      },
      playGroundChat: {
        gratifiedChat: 'SupportX Chat',
        alwaysOnline: 'Always Online',
        placeholder: 'Type something...',
        aiChatPoweredBy: 'Powered by',
        gratified: 'SupportX',
      },
      notificationLimit: {
        freeNotification: 'Your SupportX is switched off. Wait until next biling or upgrade your plan.',
        proNotification:
          'You have reached your resolution limits. Consider updating your plan or temporarly switching off SupportX.',
        premiumNotification:
          'You have reached your resolution limits. Consider contacting SupportX or temporarly switching off SupportX.',
        upgrade: 'Upgrade a Plan',
        clipboard: 'Our support@supportx.ai email was copied to the clipboard',
        unpaidInvoice: 'You have unpaid invoice. Make a payment to keep the chats',
      },
      currentSubscription: {
        currentPlan: 'CURRENT PLAN',
        downgradeSuccessToast:
          'You successfully changed your SupportX plan to Free Plan. It is scheduled to start on the next billing date.',
        nextSubscription: 'Turns To {type} on {date}',
        limitRefresh: 'Limit refresh: {date}',
        downgradeErrorToast: "Can't downgrade to free subscription, try again later",
        planDetails: 'Plan Details',
        resolutions: 'RESOLUTIONS',
        payments: 'Payments',
        changePlan: 'Change Plan',
        edit: 'Edit Billing Address',
        cancel: 'Cancel Subscription',
      },
      updateBillingText: {
        successToast: 'Billing address updated successfully',
        updateAddress: 'Update Billing Address',
        address: 'Address',
        full_name: 'Full Name',
        company_name: 'Company Name',
        state: 'State',
        postal_code: 'Postal Code',
        update: 'Update',
      },
      addPaymentMethodText: {
        cardAdded: 'Card added successfully',
        addPayment: 'Add Payment Method',
      },
      paymentHistory: {
        title: 'Payment History',
        amount: 'Amount:',
        noData: 'No Payment History',
        paid: 'Paid',
        unpaid: 'Unpaid',
      },
      paymentMethod: {
        title: 'Select Payment Method',
        cardChanged: 'You successfully changed your default card.',
        cardChangeError: "Something went wrong, we couldn't set the default card",
        deleteCard: 'Delete Card',
        add: 'Add Payment Method',
        cardRemoved: 'Card removed.',
        cardRemovedError: "Something went wrong, we couldn't remove the card",
      },
      downgradeSubscription: {
        dialogModal: {
          title: 'Are you sure?',
          message: 'If you cancel your subscription we will switch you to Free plan from next billing period.',
          cancelButton: 'Cancel Subscription',
          continueButton: 'Cancel',
        },
        isFreePlanDialogModal: {
          message: 'You already on a Free plan.',
          okayButton: 'Okay',
        },
      },
    },
  },
  salesContactForm: {
    sendRequestScreen: {
      errorMessage: 'We could not send a contact request. Please try again',
      title: `We do care about\nyour growth.`,
      subtitle: 'Request our sales team contact.',
      description: `Need a customized plan? Our sales team would love to work with you.`,
      requestButton: 'Request Enterprise Offer',
      cancelButton: 'No, thanks',
    },
    successScreen: {
      title: `Thank You.\nWe will contact\nyou soon.`,
      description: 'Our sales team is looking forward to finding a creative solution for you.',
      closeButton: 'Close',
      notificationLimit: {
        freeNotification: 'Your SupportX is switched off. Wait until next biling or upgrade your plan.',
        proNotification:
          'You have reached your resolution limits. Consider updating your plan or temporarly switching off SupportX.',
        premiumNotification:
          'You have reached your resolution limits. Consider contacting SupportX or temporarly switching off SupportX.',
        upgrade: 'Upgrade a Plan',
        clipboard: 'Our support@supportx.ai email was copied to the clipboard',
        unpaidInvoice: 'You have unpaid invoice. Make a payment to keep the chats',
      },
      currentSubscription: {
        currentPlan: 'CURRENT PLAN',
        downgradeSuccessToast:
          'You successfully changed your SupportX plan to Free Plan. It is scheduled to start on the next billing date.',
        nextSubscription: 'Turns To {type} on {date}',
        limitRefresh: 'Limit refresh: {date}',
        downgradeErrorToast: "Can't downgrade to free subscription, try again later",
        planDetails: 'Plan Details',
        resolutions: 'RESOLUTIONS',
        payments: 'Payments',
        changePlan: 'Change Plan',
        edit: 'Edit Billing Address',
        cancel: 'Cancel Subscription',
      },
      updateBillingText: {
        successToast: 'Billing address updated successfully',
        updateAddress: 'Update Billing Address',
        address: 'Address',
        full_name: 'Full Name',
        company_name: 'Company Name',
        state: 'State',
        postal_code: 'Postal Code',
        update: 'Update',
      },
      addPaymentMethodText: {
        cardAdded: 'Card added successfully',
        addPayment: 'Add Payment Method',
      },
      paymentHistory: {
        title: 'Payment History',
        amount: 'Amount:',
        noData: 'No Payment History',
        paid: 'Paid',
        unpaid: 'Unpaid',
      },
      paymentMethod: {
        title: 'Select Payment Method',
        cardChanged: 'You successfully changed your default card.',
        cardChangeError: "Something went wrong, we couldn't set the default card",
        deleteCard: 'Delete Card',
        add: 'Add Payment Method',
        cardRemoved: 'Card removed.',
        cardRemovedError: "Something went wrong, we couldn't remove the card",
      },
      downgradeSubscription: {
        dialogModal: {
          title: 'Are you sure?',
          message: 'If you cancel your subscription we will switch you to Free plan from next billing period.',
          cancelButton: 'Cancel Subscription',
          continueButton: 'Cancel',
        },
        isFreePlanDialogModal: {
          message: 'You already on a Free plan.',
          okayButton: 'Okay',
        },
      },
    },
  },

  modals: {
    subscriptionWelcomeScreen: {
      modalTitle: `Welcome\nto supportX!`,
      modalSubtitle: 'Start your subscription.',
      primaryButtonLabel: 'Choose Plan',
      secondaryButtonLabel: 'Not Now',
    },
    subscriptionPlanModal: {
      modalTitle: 'Subscribe to give supportX to your customers.',
      primaryButtonLabel: 'Choose Plan',
      secondaryButtonLabel: 'Not Now',
    },
    infoModal: {
      title: 'Please Read',
      message:
        'Processing external data sources may take some time due to the complexity of some websites and the amount of data.',
      continueButton: 'Got it',
    },
    endConversationModal: {
      label: 'End Conversation',
      title: 'Are you sure?',
      message: 'Closing conversation for the customer will end their current support conversation.',
      cancelButton: 'Cancel',
      continueButton: 'End Conversation',
    },
    inviteMemberModal: {
      title: 'Invite Team Members',
      message:
        'Invite your team members so they can help you answer customer requests, track data and handle support issues.',
      errorToastText: 'Something went wrong. Please try again later.',
      cancelButton: 'Cancel',
      sendInviteButton: 'Send Invites',
      emailPlaceholder: 'Email Address',
      inputErrorMessage: 'Email is not valid',
      notUniqueError: 'Email is already in use',
      addAnotherPerson: 'Add Another Person',
      successInviteToast: 'Invitation sent successfully',
      noValidEmailsProvided: 'No valid emails provided',
      failedToInviteUser: 'Failed to invite user',
    },
    changeViewerModal: {
      title: 'Upgrade viewer to Expert?',
      firstPart:
        'These viewers {emailsList} are already viewers of the portal. Do you want to upgrade them to the Expert role?',
      secondPart:
        'This viewer {emailsList} is already a viewer of the portal. Do you want to upgrade to the Expert role?',
      cancel: 'Cancel',
      submit: 'Upgrade to Expert',
    },
  },
  resetPasswordScreen: {
    title: 'Set Your New Password',
    passwordChanged: 'Password Successfully Changed',
    passwordChangedDescription: 'We updated your password. Get back to login.',
    newPasswordPlaceholder: 'New Password',
    newPasswordErrorText: "Password can't be empty",
    repeatPasswordPlaceholder: 'Repeat New Password',
    repeatPasswordErrorText: 'Passwords are not the same',
    returnToLogin: 'Return To Login',
    changePassword: 'Change Password',
  },
  signUpScreen: {
    joinToGratified: 'The next generation of technical support is just few steps away.<br /> Join For Free',
    welcomeScreen: {
      title: 'Welcome to supportX',
      subtitle: 'Start by entering your email',
      emailPlaceholder: 'Email Address',
      emailErrorText: 'This email is incorrect or empty',
      continueButtonLabel: 'Continue',
    },
    personalInfoScreen: {
      stepInformation: 'Step 1/2: Personal Info',
      title: 'Start using supportX for free',
      subtitle: 'Setting account for ',
      namePlaceholder: 'Your Name*',
      nameErrorText: 'Name name can`t be empty',
      companyWebsitePlaceholder: 'Company Website*',
      companyWebsiteErrorText: 'Company website is not valid',
      passwordPlaceholder: 'Password',
      nextButtonLabel: 'Next',
    },
    phoneNumberScreen: {
      stepInformation: 'Step 2/2: Verification',
      title: 'Verify using OTP',
      subtitle: 'We will send you one-time password to you mobile number',
      continueButtonLabel: 'Send Verification Code',
      phoneNumberInput: {
        phone: 'Phone Number',
        error: 'Phone number is incorrect',
      },
    },
    veryficationScreen: {
      stepInformation: 'Step 2/2: Verification',
      title: 'Verify using your email',
      subtitle: 'We will send you a one-time code to your email',
      noCode: 'Didn’t get a code?',
      resend: 'Resend ',
      codeError: 'Wrong Code. Code expired or is incorrect',
      continueButtonLabel: 'Create Account',
      agreementComponent: {
        termsInfo:
          'By creating a supportX account you’re agreeing to accept our <a href="https://www.supportx.ai/page/terms-of-use" target="_blank">Terms of Service</a>.',
        requiredInfo: '*Required',
      },
    },
    goBackText: '< Back',
  },
  sourceChangeNotification: {
    text: "There were changes made in the knowledge base. Update supportX's training to make it live.",
    button: 'Update Knowledge',
    building: "SupportX's training is updating now. Please try again later.",
  },
  images: {
    allImages: 'All Images',
    list: 'List',
    folders: 'Folders',
    allUploadedImagesWillShowUpHere: 'All uploaded images will show up here.',
    editImageData: 'Edit Image Data',
    moveTo: 'Move to',
    deleteFile: 'Delete File',
    dragYourFileHere: 'Drag your images here',
    name: 'Image Name',
    status: 'Status',
    added: 'Added',
    processing: 'Processing ...',
    type: 'Type',
    folder: 'Folder',
    chooseFolder: 'Choose Folder',
    addContentToSpecifiedFolder: 'Add content to specified folder',
    areYouSure: 'Are you sure?',
    cancelUploadModalMessage:
      'If you cancel throughout the upload, none of the images would be processed to use of an AI.',
    deleteFileModalMessageFirst: 'If you delete',
    deleteFileModalMessageSecond: 'it won’t be available for AI SupportX anymore.',
    deleteFolderError: 'Folder not deleted.',
    deleteFolderSuccess: 'Folder successfully deleted.',
    editImageModalTitle: 'Edit image data',
    newFolderModalTitle: 'New Folder',
    uploadImagesModalTitle: 'Upload Images',
    uploadImagesModalDescription:
      'Upload images, we will then analyze them with the AI, so you can edit/add move context.',
    supportedFormats: 'Supported formats: .jpg, .jpeg, .png, .webp',
    maximumSize: 'Maximum size: 20 mb',
    imagesSuccessfullyAdded: 'images successfully added.',
    changeImageDataSuccess: 'Image data changed successfully',
    changeFolderSuccess: 'The folder changed successfully',
    rename: 'Rename',
    deleteFolder: 'Delete folder',
    renameFolderModalTitle: 'Rename Folder',
    fileError: 'Image is to big. Please upload a smaller image',
    unknownSize: 'Unknown size',
    addFolderSuccess: 'The new folder added successfully',
    renameFolderSuccess: 'The folder renamed successfully',
    removedFolderToast: 'The folder removed',
    removedImageToast: 'Image removed',
    fileDeleted: 'The file has been deleted.',
    buttons: {
      uploadImages: 'Upload Images',
      editFolder: 'Edit Folder',
      renameFolder: 'Rename Folder',
      cancel: 'Cancel',
      cancelUploadAndLeave: 'Cancel upload and leave',
      delete: 'Delete',
      saveChanges: 'Save Changes',
      createFolder: 'Create Folder',
      cancelUpload: 'Cancel Upload',
    },
  },
  files: {
    move: 'Move',
    moveFile: 'Move File',
    moveFolder: 'Move Folder',
    currentFolder: 'Current Folder',
    chooseNewDirectory: 'Choose new directory.',
    noFolders: 'No folders',
    allFiles: 'All Files',
    list: 'List',
    folders: 'Folders',
    allUploadedFilesWillShowUpHere: 'All uploaded files will show up here.',
    downloadFile: 'Download File',
    moveTo: 'Move to',
    deleteFile: 'Delete File',
    dragYourFileHere: 'Drag your files here',
    preview: 'Preview',
    name: 'File Name',
    status: 'Status',
    added: 'Added',
    addedDate: 'Added Date',
    fileError: 'Files is to big. Please upload a smaller file',
    unknownSize: 'Unknown size',
    processing: 'Processing ...',
    type: 'Type',
    folder: 'Folder',
    folderName: 'Folder Name',
    fileName: 'File Name',
    source: 'Source',
    chooseFolder: 'Choose Folder',
    addContentToSpecifiedFolder: 'Add content to specified folder',
    areYouSure: 'Are you sure?',
    cancelUploadModalMessage:
      'If you cancel throughout the upload, none of the files would be processed to use of an AI.',
    deleteFileModalMessageFirst: 'If you delete',
    deleteFileModalMessageSecond: 'it won’t be available for AI SupportX anymore.',
    deleteFolderError: 'Folder not deleted.',
    deleteFolderSuccess: 'Folder deleted.',
    editFileModalTitle: 'Edit file data',
    newFolderModalTitle: 'New Folder',
    uploadFilesModalTitle: 'Upload Files',
    uploadFilesModalDescription:
      'Upload files, we will then analyze them with the AI, so you can edit/add move context.',
    supportedFormats:
      'Supported formats: .c, .cpp, .css, .csv, .docx, .html, .java, .js, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .ts, .txt, .xlsx, .xml',
    maximumSize: 'Max 10.000 files, 512mb per file',
    fileSuccessfullyAdded: 'file successfully added.',
    filesSuccessfullyAdded: 'files successfully added.',
    fileAlreadyExists: 'file with this name already exists.',
    filesAlreadyExist: 'files with these names already exist.',
    fileFailedToUpload: 'file failed to upload.',
    filesFailedToUpload: 'files failed to upload.',
    folderSuccessfullyAdded: 'Folder successfully added.',
    folderNotAdded: 'Folder not added.',
    rename: 'Rename',
    deleteFolder: 'Delete folder',
    renameFolderModalTitle: 'Rename Folder',
    renameFileModalTitle: 'Rename File',
    fileDeleted: 'The file has been deleted.',
    closeModalError: 'You cannot close the modal while files are uploading.',
    cantMoveFolderInChild: 'You cannot move a folder to its child folder.',
    buttons: {
      uploadFiles: 'Upload Files',
      editFolder: 'Edit',
      renameFolder: 'Rename',
      cancel: 'Cancel',
      cancelUploadAndLeave: 'Cancel upload and leave',
      delete: 'Delete',
      saveChanges: 'Save Changes',
      createFolder: 'Create Folder',
      cancelUpload: 'Cancel Upload',
    },
  },
}
