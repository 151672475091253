import { api } from 'redux/rtkQuery'
import styles from './DeleteFolderModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions, modalSelector } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import { imagesActions, imagesSelector } from 'redux/images/slice'
import { IGetImagesResponse } from 'types/api'
import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'

const { wrapper, title, description } = styles

interface IProps {
  onClose?: () => void
}

const DeleteFolderModalContent = ({ onClose }: IProps) => {
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const imagesStored = useSelector(imagesSelector)
  const text = modalContext.folderName ? messages.files : messages.images

  const [deleteImage, { isLoading }] = api.useDeleteImageMutation()
  const [deleteFolder, { isLoading: isDeleteFolderLoading }] = api.useDeleteFolderMutation()

  const handleDelete = async () => {
    if (modalContext.folderId) {
      try {
        await deleteFolder({ botId: bot.id, folderId: `/${modalContext.folderId}` })
        toastFuncSuccess(
          modalContext.folderName
            ? text.deleteFolderSuccess.replace(' ', ` "${modalContext.folderName}" `)
            : text.deleteFolderSuccess
        )
      } catch (error) {
        console.error('Error deleting folder:', error)
        toastFuncError(text.deleteFolderError)
      }
    } else {
      dispatch(imagesActions.deleteFolder({ folderName: modalContext.category }))

      const imagesOfCategory = imagesStored.images.filter(
        (image: IGetImagesResponse) => image.category === modalContext.category
      )

      if (imagesOfCategory.length > 0) {
        try {
          const promises = imagesOfCategory.map((image: IGetImagesResponse) => {
            return deleteImage({
              botId: bot.id,
              imageId: image.id,
            })
          })

          await Promise.all(promises)
        } catch (error) {
          console.error('Error deleting images:', error)
        }
      }
    }

    dispatch(modalActions.setShowModal({ modalType: MODAL_TYPES.DELETE_FOLDER }))
  }

  return (
    <div className={wrapper}>
      <p className={title}>{text.areYouSure}</p>
      <p className={description}>
        {text.deleteFileModalMessageFirst} {modalContext.fileName || modalContext.folderName}{' '}
        {text.deleteFileModalMessageSecond}{' '}
      </p>
      <ActionButton
        label={text.buttons.delete}
        loading={isLoading || isDeleteFolderLoading}
        buttonType={ActionButtonType.WARNING}
        size={ActionButtonSize.MEDIUM}
        onPress={handleDelete}
      />
      <ActionButton
        label={text.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default DeleteFolderModalContent
