import { FC } from 'react'

const LogoutIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1016_5100"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1016_5100)">
        <path
          d="M12.7404 20.5C12.5275 20.5 12.3493 20.4282 12.2058 20.2846C12.0622 20.141 11.9904 19.9628 11.9904 19.75C11.9904 19.5371 12.0622 19.3589 12.2058 19.2154C12.3493 19.0718 12.5275 19 12.7404 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H12.7404C12.5275 4.99998 12.3493 4.92818 12.2058 4.7846C12.0622 4.64102 11.9904 4.46282 11.9904 4.25C11.9904 4.03718 12.0622 3.85898 12.2058 3.7154C12.3493 3.5718 12.5275 3.5 12.7404 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H12.7404ZM12.0308 12.75H4.25C4.03718 12.75 3.85898 12.6782 3.7154 12.5346C3.5718 12.391 3.5 12.2128 3.5 12C3.5 11.7872 3.5718 11.609 3.7154 11.4654C3.85898 11.3218 4.03718 11.25 4.25 11.25H12.0308L10.1077 9.32693C9.96927 9.18846 9.89844 9.01955 9.89523 8.8202C9.89203 8.62085 9.96286 8.44361 10.1077 8.28848C10.2526 8.13336 10.4282 8.05323 10.6346 8.0481C10.841 8.04297 11.0218 8.11797 11.1769 8.2731L14.2711 11.3673C14.4519 11.5481 14.5423 11.759 14.5423 12C14.5423 12.241 14.4519 12.4519 14.2711 12.6327L11.1769 15.7269C11.0282 15.8756 10.8516 15.949 10.6471 15.9471C10.4427 15.9451 10.2629 15.8666 10.1077 15.7115C9.96286 15.5563 9.89299 15.3781 9.89813 15.1769C9.90324 14.9756 9.97823 14.8025 10.1231 14.6577L12.0308 12.75Z"
          fill="#686868"
        />
      </g>
    </svg>
  )
}

export default LogoutIcon
