import { PaymentMethod, StripeError } from '@stripe/stripe-js'

export type Auth = {
  id: string
  token: string
}

export type UploadConfig = {
  region: string
  accessKeyId: string
  secretAccessKey: string
}

// DO NOT change the order of the enum bellow (UserRoles)
export enum UserRoles {
  ADMIN = 'admin',
  EXPERT = 'expert',
  AGENT = 'agent',
  VIEWER = 'viewer',
}

export enum TeamManagementUserRoles {
  ADMIN = 'admin',
  EXPERT = 'expert',
  AGENT = 'agent',
}

export enum UserRegistrationType {
  DIRECT = 'direct',
  INVITED = 'invited',
  PENDING_INVITATION = 'pending_invitation',
}

export type User = {
  id: string
  phone_number: string
  name: string
  login: string
  avatar_url?: string
  emailOtp?: string
  company_name?: string
  company_id: string
  timezone?: string
  is_verified: boolean
  role: UserRoles
  created_at: string
  updated_at: string
  treat_as_offline: boolean
  registration_type: UserRegistrationType
  last_activity_at: string
}

export type Viewers = {
  currentPage: number
  lastPage: number
  perPage: number
  total: number
  data: User[]
}

export enum BotStatus {
  FROZEN = 'frozen',
  OFFLINE = 'offline',
  READY = 'ready',
}

export enum HandoffType {
  LIVE_CHAT = 'live_chat',
  INBOX = 'inbox',
  EMAIL = 'email',
  ZENDESK = 'zendesk',
}

export type TechBot = {
  id: string
  name: string
  title: string
  code: string
  logo_url: string | null
  handoffEmail: string | null
  handoffType: HandoffType | null | string
  status: BotStatus
  welcome_message: string
  offline_message: string
  widget_message: string
  color_primary: string
  color_secondary: string
  authorized_url_patterns: string[]
  company_id: string
  created_at: string
  updated_at: string
  last_build_at: string | null
  company_website_domain: string
  isInstalled: boolean
  isUpdating: boolean
  include_grounding: boolean
  // Portal settings
  portal_prefix: string
  portal_primary_color: string
  portal_secondary_color: string
  portal_logo_url: string
  portal_header_image_url: string
  portal_initial_message: string
  portal_sub_message: string
  last_urls_sync_at: string
  last_knowledge_sync_at: string
  last_conversations_sync_at: string
  last_images_sync_at: string
  isWidgetEnabled: boolean
  portal_auth: boolean | null | undefined
}

export type Company = {
  id: string
  name: string
  users: { id: string }[]
  botId: string
  created_at: string
  url: string | null
  updated_at: string
}

export type DashboardData = {
  chart: ChartData[]
  conversations: {
    gptConversations: number
    agentConversations: number
    total: number
    totalResolved: number
    totalUnresolved: number
    totalProcessing: number
    totalOngoing: number
    resolvedByGPT: number
    resolvedByAgent: number
    satisfaction: SentimentData
  }
  lastConversations: ConversationPreview[]
}

export type ChartData = {
  data: string
  label: string
}

export type SentimentData = { [key: string]: number }

export enum ConversationStatus {
  // Handoff mode
  // conversation ended
  ENDED_BY_AGENT = 'ENDED_BY_AGENT',
  // Closed by agent (only for LIVE and HANDOFF mode)
  ENDED_BY_TIMEOUT = 'ENDED_BY_TIMEOUT',
  // conversation is active
  GPT = 'GPT',
  ENDED_BY_CUSTOMER = 'ENDED_BY_CUSTOMER',

  // Live mode
  HANDOFF = 'HANDOFF',
  // GPT mode
  LIVE = 'LIVE', // Closed by timeout (only for GPT mode)

  // conversation analyzed by AI
  RESOLVED_WITH_GPT = 'RESOLVED_WITH_GPT',
  // Unresolved with AI
  RESOLVED_WITH_HUMAN = 'RESOLVED_WITH_HUMAN',
  // Resolved with AI
  UNRESOLVED_WITH_GPT = 'UNRESOLVED_WITH_GPT', // Resolved with human help
  UNRESOLVED_WITH_HUMAN = 'UNRESOLVED_WITH_HUMAN', // Unresolved with human help

  ZENDESK_HANDOFF = 'ZENDESK_HANDOFF',
}

export type UpdateBotData = Partial<
  Pick<
    TechBot,
    | 'name'
    | 'company_website_domain'
    | 'title'
    | 'status'
    | 'welcome_message'
    | 'widget_message'
    | 'offline_message'
    | 'logo_url'
    | 'handoffEmail'
    | 'handoffType'
    | 'color_primary'
    | 'color_secondary'
    | 'authorized_url_patterns'
    | 'portal_logo_url'
    | 'portal_primary_color'
    | 'portal_secondary_color'
    | 'portal_initial_message'
    | 'portal_sub_message'
    | 'isWidgetEnabled'
    | 'portal_auth'
    | 'include_grounding'
  >
>

export enum AuthorType {
  // Message authors
  AGENT = 'AGENT',
  AI = 'AI',
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',

  // System events
  EVENT = 'EVENT',

  // Informational messages
  INFO = 'INFO',

  // Ask for customer actions
  PROVIDE_EMAIL = 'PROVIDE_EMAIL',
  PROVIDE_PHONE = 'PROVIDE_PHONE',
  PROVIDE_EMAIL_OR_PHONE = 'PROVIDE_EMAIL_OR_PHONE',
  PROVIDE_REQUEST_HUMAN = 'PROVIDE_REQUEST_HUMAN',
}

export type Message = {
  id: string
  text: string
  author_type: string
  created_at: string
  name?: string
  logo_url?: string
  conversation_id: string
  is_read: boolean
  status: string
  sentiment: number
}

export type ChatMessage = {
  message: Message
}

export type ConversationPreview = {
  id: string
  status: ConversationStatus
  created_at: string
  message: string
  sentiment: number
}

// Resources
export enum ResourceLinkStatus {
  ADDED = 'added',
  FINISHED = 'finished',
  PROCESSING = 'processing',
  FAILED = 'failed',
}

export type ParsedResourceLink = {
  url: string
  bot_id: string
  created_at: string
  id: string
  status: ResourceLinkStatus
  updated_at: string
  download_pdf: boolean
  last_processed_at: string | null
  folder_id: string | null // File managment folder id
  file_ids: string[] | null // File managment file ids
  found_images: number | null // In future we maybe save images
  found_links: number | null // In future we maybe save links
  found_pdfs: number | null
}

export type WebsiteResource = {
  websites: [ParsedResourceLink]
  page: number
  limit: number
  nextPage: number
  total: number
}

export type NewResourceLink = Omit<ParsedResourceLink, 'id'>

export type UpdateResourceLink = Partial<Omit<NewResourceLink, 'created_at' | 'updated_at' | 'bot_id' | 'url'>>

export enum ResourceType {
  CRAWLED_FILE = 'crawled_file',
  CRAWLED_PDF = 'crawled_pdf',
  FILE = 'file',
  ZENDESK_FILE = 'zendesk_file',
  KNOWLEDGE_FILE = 'knowledge_file',
  SUPPORTX_CONVERSATIONS = 'supportx_conversations',
}

export enum ResourceStatus {
  SAVED_ON_API = 'saved_on_api',
  SAVED_ON_AI = 'saved_on_ai',
}

export type ParsedResource = {
  id: string
  bot_id: string
  type: ResourceType
  name: string
  s3_url: string
  disabled: boolean
  status: ResourceStatus
  openai_file_id?: string // file id from openai
  assistant_id?: string // what assistant this resource belongs to
  created_at: string
  updated_at: string
}

export type NewResourceBody = Omit<ParsedResource, 'id'>

export type UpdateResourceBody = Partial<Omit<NewResourceBody, 'created_at' | 'updated_at' | 'bot_id' | 'type'>>

export type FileResource = {
  resources: [ParsedResource]
  page: number
  limit: number
  nextPage: number
  total: number
}

export type CountResources = {
  images: {
    crawled: number
    added: number
  }
  snippets: {
    synced: number
    total: number
  }
  links: {
    crawled: number
    added: number
  }
  files: {
    crawled: number
    added: number
  }
}

export type Knowledge = {
  knowledges: ParsedKnowledge[]
}

export enum KnowledgeSource {
  Agent = 'AGENT',
  GPT = 'GPT',
  Zendesk = 'ZENDESK',
}

export type ParsedKnowledge = {
  approved_by?: string
  bot_id: string
  conversation_id?: string
  created_at: string
  description: string
  disabled: boolean
  generated_by: KnowledgeSource
  id: string
  resource_id?: string
  title: string
  updated_at: string
}

export type KnowledgeBody = Partial<Pick<ParsedKnowledge, 'id' | 'description' | 'disabled'>>
export enum SourceType {
  FILE = 'file',
  KNOWLEDGE = 'knowledge',
  URL = 'url',
  ZENDESK = 'zendesk_tickets',
  SUPPORTX_CONVERSATIONS = 'supportx_conversations',
  IMAGES = 'images',
}

export type ParsedSourceChange = {
  bot_id: string
  id: string
  processing: boolean
  type: SourceType
  updated_at: string
}

export type ParsedConversationMessage = {
  author_type: AuthorType
  created_at: string
  id: string
  logo_url: string | null
  name: string | null
  text: string
  updated_at: string
}

export type ParsedConversation = {
  bot_id: string
  chat_user_email: string
  chat_user_phone_number: string
  company_id: string
  created_at: string
  ended_at: string
  id: string
  messages: ParsedConversationMessage[]
  moved_to_live_agent_at: string | null
  status: ConversationStatus
  thread_id: string
  updated_at: string
  user_waits_for_response: boolean
  website_url: string
  is_read: boolean
  sentiments: ParsedConversationSentiment[]
  summary: string
  category: string
  is_agent_joined: boolean
  company_name: string
  hasConversationData: boolean
}

export type ParsedConversationSentiment = {
  conversation_id: string
  created_at: string
  description: string | null
  message_id: string
  satisfaction_level: number
}

// SUBSCRIPTION

export type Price = {
  id: string
  currency: string
  type: string
  unit_amount_decimal: string
  recurring_interval: 'year' | 'month'
}

export type Product = {
  isFree: boolean
  productId: string
  productName: string
  prices: Price[]
  featureList: {
    label: string
    disable: boolean
  }[]
  shouldContactToSales: boolean
}

export type Payment = {
  id: string
  company_id: string
  customer_id: string
  resolution_count: number | null
  payment_intent_id: string | null
  payment_method_ids: string[] | null
}

export type Subscription = {
  id: string
  currency: string
  interval: 'month' | 'year'
  createdAt: string
  nextBillingAt: string
  productName: string
  price: number
  isFree: boolean
  plan: {
    product: string
  }
  paymentDetails: {
    count: number
    planLimit: number
    priceForOverLimit: number
    overPay: number
  }
}

export type SubscriptionSchedule = {
  current: {
    productId: string
    type: string
    id: string
    until: number | null
    nextBillingDate: number | null
    isFree: boolean
  }
  next: {
    productId: string
    type: string
    id: string
    until: number | null
    startDate: number | null
    isFree: boolean
  } | null
}

export type Card = {
  id: string
  type: string
  last4: string
  isDefault: boolean
}

export type PaymentHistoryType = {
  id: string
  amount: number
  created: number
  currency: string
  description: string
  status: string
  invoice: string
  card: {
    brand: string
    last4: string
  }
}

export type PaymentIntentResponseType = {
  subscription_id: string
  client_secret: string
  status?: string
}

export interface CreatePaymentMethodResponse {
  paymentMethod?: PaymentMethod
  error?: StripeError
}

export type CustomerBillingInfo = {
  address1: string
  full_name: string
  company_name: string
  state: string
  postal_code: string
  is_invoice: boolean
}

export type BillingInformation = {
  address: {
    line1: string
    state: string
    postal_code: string
  }
  name: string
  metadata: {
    company_name: string
  }
}

export const enum ImageStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ERROR = 'error',
  PROCESSING = 'processing',
}

export interface IGetImagesResponse {
  bot_id: string
  category: string
  company_id: string
  created_at: string
  description: string
  fileName: string
  fileSize: number
  id: string
  s3Url: string
  status: ImageStatus
  title: string
  updated_at: string
}

export enum UPLOADED_DATA_TYPES {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum FILE_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum FolderType {
  CRAWLER = 'crawler',
  GOOGLE_DRIVE = 'google_drive',
  USER = 'user',
}

export enum FileStatus {
  PROCESSING = 'processing',
  IN_SYSTEM = 'in_system',
  FAILED = 'failed',
}

export interface IUploadedFile {
  id: string
  name: string
  size: number
  found_on: string | null
  created_at: string
  updated_at: string
  mine_type: string | unknown
  download_url: string
  s3_url: string
  created_by: string
  status: FileStatus | undefined
}

export interface IUploadedFolder {
  id: string
  name: string
  created_at: string
  updated_at: string
  is_locked: boolean
  folder_type: FolderType
  created_by: string
  files_in_folder: number
}

export interface IGetFilesData {
  files: IUploadedFile[]
  folders: IUploadedFolder[]
  currentFolderId: string
  limit: number
  nextPage: number | null
  page: number
  totalPages: number
  total: number
  totalFiles: number
}

export type TSearchData = IUploadedFile | IUploadedFolder

export const enum PROGRESS_STATUS {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export type Urls = {
  widget_url: string
  portal_url: string
}

export enum IntegrationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  READY_TO_INSTALL = 'READY_TO_INSTALL',
  INSTALLED = 'INSTALLED',
}

export type Instruction = {
  id: string
  company_id: string
  created_at: string
  updated_at: string
  mission: string
  core: string
  guardrails: string
  extras: string
  description: string
  gathering: string | null
  is_enabled_custome_instructions: boolean
  is_enabled_gathering_instructions: boolean
}

export type UserByEmailData = {
  id: string
  company_id: string
  role: UserRoles
  email: string
}

export type NormalizedGatheringData = {
  checked: boolean
  company_id: string
  conversation_id: string
  hasConversationData: boolean
  created_at: string
  id: string
  updated_at: string
}

export type GatheringData = {
  company_id: string
  conversation_id: string
  created_at: string
  hasConversationData: boolean
  id: string
  updated_at: string
}

export type ConversationGatheringData = {
  currentPage: number
  data: GatheringData[]
  lastPage: number
  perPage: number
  total: number
}
