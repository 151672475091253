import { Avatar, Card, CardContent, CardHeader, Typography } from '@mui/material'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { messages } from 'constants/messages'
import Modal from 'modules/modal/Modal'
import { useState } from 'react'

interface IProps {
  logo: string
  title: string
  description: string
  modalContent?: JSX.Element
}

const DriveCard = ({ logo, title, description, modalContent }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { techbots } = messages

  const handleSetIsModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <Card sx={{ maxWidth: 282, padding: '16px', borderRadius: '16px', boxShadow: 'none', border: '1px solid #dcdcdc' }}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            src={logo}
            sx={{
              backgroundColor: '#F6F6F6',
              '& img': {
                width: '26px',
                height: '26px',
              },
            }}
          />
        }
        sx={{ padding: '0 0 24px 0' }}
      />
      <CardContent sx={{ padding: '0 !important' }}>
        <Typography
          sx={{ marginBottom: '8px', fontSize: '16px', fontWeight: 'bold', lineHeight: '22px', color: '#353535' }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ marginBottom: '12px', fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#777777' }}
        >
          {description}
        </Typography>
        <ActionButton
          label={techbots.techBotDataSources.connectGoogleDrive}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          onPress={handleSetIsModalOpen}
        />
      </CardContent>

      {modalContent && (
        <Modal open={isModalOpen} onClose={handleSetIsModalOpen} maxWidth="450px">
          {modalContent}
        </Modal>
      )}
    </Card>
  )
}

export default DriveCard
