import { FC, useState, ChangeEvent, useEffect } from 'react'
import { Dialog, Divider, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconHelp from 'assets/icons/iconHelp'
import DropdownMenu from 'components/dropdownMenu/DropdownMenu'
import TeamManagementIcon from 'assets/icons/teamManagementIcon'
import styles from './PortalPreview.module.scss'
import RolesModal from 'modules/teamManagement/components/rolesModal/RolesModal'
import InviteMemberModal from 'modules/teamManagement/components/inviteMemberModal/InviteMemberModal'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { User, UserRegistrationType } from 'types/api'
import PaginationComponent from 'components/pagination/PaginationComponent'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import { messages } from 'constants/messages'
interface PortalPreviewModalProps {
  open: boolean
  onClose: () => void
  count: number
}

const { table, cellMoreTh, noViewers, pendingInvitation } = styles

const { viewersModal } = messages.portal

const PortalViewersModal: FC<PortalPreviewModalProps> = ({ open, onClose, count }) => {
  const user: User = useAppSelector((state) => state.user)

  const { data: companyUsers } = api.useGetCompanyUsersQuery(
    { id: user.company_id },
    { skip: !user.company_id, refetchOnMountOrArgChange: true }
  )
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false)
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false)
  const [page, setPage] = useState(1)

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleInviteModalVisible = () => {
    setIsInviteModalVisible(!isInviteModalVisible)
  }
  const handleRoleModalVisible = () => {
    setIsRoleModalVisible(!isRoleModalVisible)
  }

  const { data: portalViewers } = api.useGetPortalViewersQuery({ page }, { refetchOnMountOrArgChange: true })
  const [removeInvitation] = api.useRemoveInvitationToPortalMutation()

  const handleRemoveInvitation = async (viewer: User) => {
    try {
      await removeInvitation({ viewerId: viewer.id }).unwrap()
      toastFuncNotification(`${viewersModal.invitationRemovedFor} ${viewer.login}`)
    } catch {
      toastFuncError(viewersModal.invitationDoNotRemoved)
    }
  }

  useEffect(() => {
    if (portalViewers && !portalViewers.data.length && page > 1) {
      setPage(page - 1)
    }
  }, [portalViewers, page])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '698px',
          width: '100%',
          padding: '24px',
          borderRadius: '24px',
          position: 'relative',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', boxSizing: 'border-box' }}>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Manrope',
              fontWeight: '600',
              lineHeight: '22px',
              letterSpacing: '-0.1px',
              color: '#353535',
              marginBottom: '8px',
            }}
          >
            {viewersModal.managePortalAccess}
          </Typography>
          <Typography sx={{ fontFamily: 'Manrope', lineHeight: '22px', letterSpacing: '0.2px', color: '#777777' }}>
            {viewersModal.restricted}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontFamily: 'Manrope', lineHeight: '22px', letterSpacing: '-0.1px', color: '#353535' }}>
            {count} {viewersModal.users}
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <ActionButton
              icon={<IconHelp fillColor="#5E36FF" style={{ margin: '0 auto' }} />}
              size={ActionButtonSize.MEDIUM}
              buttonType={ActionButtonType.INFO}
              styleBtn={{ padding: '8px' }}
              onPress={handleRoleModalVisible}
            />
            <ActionButton
              label={viewersModal.invitePortalUsers}
              size={ActionButtonSize.MEDIUM}
              buttonType={ActionButtonType.PRIMARY}
              onPress={handleInviteModalVisible}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ border: '1px solid', borderColor: '#DCDCDC', borderRadius: '8px' }}>
            <table className={table}>
              <thead>
                <tr>
                  <th>{viewersModal.email}</th>
                  <th>{viewersModal.invitationStatus}</th>
                  <th className={cellMoreTh}></th>
                </tr>
              </thead>
              <tbody>
                {portalViewers?.data?.length ? (
                  portalViewers.data.map((viewer) => (
                    <tr
                      key={viewer.id}
                      className={
                        viewer.registration_type === UserRegistrationType.PENDING_INVITATION ? pendingInvitation : ''
                      }
                    >
                      <td>{viewer.login}</td>
                      <td>
                        {viewer.registration_type === UserRegistrationType.PENDING_INVITATION
                          ? viewersModal.pendingInvite
                          : viewer.registration_type === UserRegistrationType.INVITED
                            ? viewersModal.viewer
                            : '-'}
                      </td>
                      <td>
                        <DropdownMenu>
                          <MenuItem onClick={() => handleRemoveInvitation(viewer)}>
                            <TeamManagementIcon />
                            <Typography>{viewersModal.removeInvitation}</Typography>
                          </MenuItem>
                        </DropdownMenu>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={noViewers}>
                    <td></td>
                    <td>{viewersModal.noViewers}</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
          {!!portalViewers?.total && portalViewers.total > 1 && (
            <PaginationComponent
              totalPages={portalViewers.lastPage}
              page={portalViewers.currentPage}
              handlePageChange={handleChangePage}
            />
          )}
        </Box>

        <Divider />
        <ActionButton
          label={viewersModal.done}
          size={ActionButtonSize.MEDIUM}
          onPress={onClose}
          styleBtn={{ marginLeft: 'auto' }}
        />
      </Box>
      <RolesModal isOpen={isRoleModalVisible} handleVisible={handleRoleModalVisible} />
      {isInviteModalVisible ? (
        <InviteMemberModal
          title={
            user.company_name ? viewersModal.inviteUsers.replace('{companyName}', user.company_name) : 'Invite Users'
          }
          message={viewersModal.inviteYourTeam}
          onClose={handleInviteModalVisible}
          invitedUsers={companyUsers}
          isPortalInvites={true}
        />
      ) : null}
    </Dialog>
  )
}

export default PortalViewersModal
