import { IUploadedFile, IUploadedFolder } from 'types/api'
import styles from './DataTable.module.scss'
import { Breadcrumbs } from '../Files'
import SmallFolderIcon from 'assets/icons/smallFolderIcon'
import { useState } from 'react'
import { MoveTo } from '../MoveTo'
import { FolderActionsMenu } from '../FolderActionsMenu'
import HighlightedText from 'components/highlightedText/HighlightedText'
import { themeColors } from 'styles/colors'
import { Typography } from '@mui/material'

const { tdPreview, grayText, previewContainer, cellMore, info } = styles

interface IProps {
  folder: IUploadedFolder
  setBreadcrumbs: (folderName: string, path: string) => void
  breadcrumbs: Breadcrumbs
  searchValue?: string
}

const FolderTr = ({ folder, setBreadcrumbs, breadcrumbs, searchValue }: IProps) => {
  const dateObj = new Date(folder.created_at)
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObj.getUTCFullYear()
  const formattedDate = `${month}/${day}/${year}`

  const handleOnClick = () => {
    setBreadcrumbs(folder.name, folder.id)
  }

  const [selectedMoveToFolder, setSelectedMoveToFolder] = useState<IUploadedFolder | IUploadedFile | null>(null)
  const handleMoveTo = (folder: IUploadedFolder) => {
    setSelectedMoveToFolder(folder)
  }

  return (
    <>
      {selectedMoveToFolder && (
        <MoveTo
          selectedEntry={selectedMoveToFolder}
          setSelectedEntry={setSelectedMoveToFolder}
          currentPath={breadcrumbs}
        />
      )}
      {folder && (
        <tr key={folder.id}>
          <td className={tdPreview} onClick={handleOnClick}>
            <div className={previewContainer}>
              <SmallFolderIcon />
            </div>
            <div className={info}>
              {searchValue ? (
                <HighlightedText text={folder.name} word={searchValue} />
              ) : (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    letterSpacing: '-0.4px',
                    color: themeColors.darkGray,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {folder.name}
                </Typography>
              )}
              <p className={grayText}>File count: {folder.files_in_folder}</p>
            </div>
          </td>
          <td>{formattedDate}</td>
          <td>{`Folder`}</td>
          <td>{folder.created_by}</td>
          <td></td>
          <td className={cellMore}>
            <FolderActionsMenu folder={folder} handleMoveTo={handleMoveTo} />
          </td>
        </tr>
      )}
    </>
  )
}

export default FolderTr
