import googleDriveLogo from 'assets/images/googleDriveLogo.png'

import { messages } from 'constants/messages'
import DriveCard from 'components/driveCard/DriveCard'
import { Box } from '@mui/material'
import ConnectGoogleDriveModal from 'modules/modal/ConnectGoogleDriveModal/ConnectGoogleDriveModal'

const ExternalDrives = () => {
  const { techbots } = messages

  return (
    <Box>
      <DriveCard
        logo={googleDriveLogo}
        title={techbots.techBotDataSources.googleDrive}
        description={techbots.techBotDataSources.googleDriveDescription}
        modalContent={<ConnectGoogleDriveModal />}
      />
    </Box>
  )
}

export default ExternalDrives
