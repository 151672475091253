import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'
import { useOutletContext } from 'react-router-dom'
import { Note } from 'components/noteComponent/Note'
import { Divider, Typography } from '@mui/material'
import { Switch } from '@mui/material'
import { Box } from '@mui/system'
import { SXObject } from '../techBotDetails/constants'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import { logger } from 'utils/logger'
import CustomLoader from 'components/customLoader/CustomLoader'
import InstructionField from './InstructionField'
import { messages } from 'constants/messages'
import { InferType } from 'yup'

type FormData = InferType<typeof schema>

const Instructions = () => {
  const { instructions: instructionsMessages } = messages

  const user = useAppSelector((state: ReduxState) => state.user)
  const {
    data: instructions,
    isLoading: isGettingInstructions,
    refetch,
    isFetching: isFetchingInstructions,
  } = api.useGetInstructionsQuery({ companyId: user.company_id }, { skip: !user, refetchOnMountOrArgChange: true })

  const customInstructionsEnabled = instructions?.is_enabled_custome_instructions ?? false
  const dataGatheringEnabled = instructions?.is_enabled_gathering_instructions ?? false

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mission: instructions?.mission || '',
      coreInstructions: instructions?.core || '',
      guardrails: instructions?.guardrails || '',
      extras: instructions?.extras || '',
      dataGathering: instructions?.gathering || '',
    },
  })

  const { mission, coreInstructions, guardrails, extras, dataGathering } = watch()
  const hasDataGatheringChanged = dataGathering !== (instructions?.gathering ?? '')
  const hasCustomInstructionsChanged =
    mission !== (instructions?.mission ?? '') ||
    coreInstructions !== (instructions?.core ?? '') ||
    guardrails !== (instructions?.guardrails ?? '') ||
    extras !== (instructions?.extras ?? '')

  const [setShouldShowSaveButton, setReceivedFunction, setIsDisabled, setShowModalForInstructions] =
    useOutletContext<any>()

  useEffect(() => {
    if (instructions) {
      setValue('mission', instructions?.mission || '')
      setValue('coreInstructions', instructions?.core || '')
      setValue('guardrails', instructions?.guardrails || '')
      setValue('extras', instructions?.extras || '')
    }

    if (instructions?.guardrails) {
      setValue('dataGathering', instructions.gathering || '')
    }
  }, [instructions, instructions?.gathering, setValue])

  const [updateInstructions] = api.useUpdateInstructionsMutation()
  const [updateDataGathering] = api.useUpdateDataGatheringMutation()
  const [toggleDataGathering] = api.useToggleDataGatheringMutation()
  const [restoreInstructions] = api.useRestoreInstructionsMutation()

  const onHandleSaveChanges = useCallback(async () => {
    if (hasCustomInstructionsChanged) {
      try {
        await updateInstructions({
          companyId: user.company_id,
          mission: getValues('mission') || '',
          core: getValues('coreInstructions') || '',
          guardrails: getValues('guardrails') || '',
          extras: getValues('extras') || '',
        })
        toastFuncNotification(instructionsMessages.changeInstructionsToast)
      } catch (error) {
        logger.error(`error`, error)
      }
    }

    if (dataGathering !== (instructions?.gathering ?? '')) {
      try {
        await updateDataGathering({
          dataGathering: getValues('dataGathering') || '',
        })
        toastFuncNotification(instructionsMessages.changeDataGatheringToast)
      } catch (error) {
        logger.error('error', error)
      }
    }
  }, [
    hasCustomInstructionsChanged,
    dataGathering,
    instructions?.gathering,
    updateInstructions,
    user.company_id,
    getValues,
    instructionsMessages.changeInstructionsToast,
    instructionsMessages.changeDataGatheringToast,
    updateDataGathering,
  ])

  useEffect(() => {
    if (!isGettingInstructions) {
      setShouldShowSaveButton(hasCustomInstructionsChanged || hasDataGatheringChanged)
      setIsDisabled(false)
    }
  }, [
    hasCustomInstructionsChanged,
    hasDataGatheringChanged,
    isGettingInstructions,
    setIsDisabled,
    setShouldShowSaveButton,
  ])

  useEffect(() => {
    setShowModalForInstructions(true)
    setReceivedFunction(() => onHandleSaveChanges)
    return () => setReceivedFunction(null)
  }, [onHandleSaveChanges, setReceivedFunction, setShowModalForInstructions])

  const handleSwitchChange = async (
    key: 'customInstructionsEnabled' | 'dataGatheringEnabled',
    currentValue: boolean
  ) => {
    const newState = !currentValue

    if (key === 'dataGatheringEnabled') {
      try {
        await toggleDataGathering({ isEnable: newState })
      } catch (error) {
        logger.error('error', error)
      }
    }

    if (key === 'customInstructionsEnabled') {
      try {
        await restoreInstructions({ companyId: user.company_id, isEnable: newState }).unwrap()
      } catch (error) {
        logger.error('error', error)
      }
    }

    await refetch()
  }

  if (isGettingInstructions) return <CustomLoader />

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '748px',
        padding: '20px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        overflow: 'auto',
      }}
    >
      {isFetchingInstructions && <CustomLoader />}
      <Note title={instructionsMessages.noteTitle} description={instructionsMessages.noteDescription} />
      <Divider />
      <Box
        sx={{
          border: '1px solid #DCDCDC',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          gap: '2px',
        }}
      >
        <Box>
          <Typography
            sx={{ fontFamily: 'Manrope', fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: '#353535' }}
          >
            {instructionsMessages.dataGathering}
          </Typography>
          <Typography
            sx={{ fontFamily: 'Manrope', fontWeight: '500', fontSize: '14px', lineHeight: '22px', color: '#777777' }}
          >
            {instructionsMessages.dataGatheringDescription}
          </Typography>
        </Box>
        <Switch
          checked={dataGatheringEnabled}
          onChange={() => handleSwitchChange('dataGatheringEnabled', dataGatheringEnabled)}
          sx={SXObject}
        />
      </Box>
      {dataGatheringEnabled && (
        <>
          <Divider />
          <InstructionField
            name="dataGathering"
            control={control}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
          />
          <Divider />
        </>
      )}
      <Box
        sx={{
          border: '1px solid #DCDCDC',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          gap: '2px',
        }}
      >
        <Box>
          <Typography
            sx={{ fontFamily: 'Manrope', fontWeight: '600', fontSize: '14px', lineHeight: '22px', color: '#353535' }}
          >
            {instructionsMessages.customTitle}
          </Typography>
          <Typography
            sx={{ fontFamily: 'Manrope', fontWeight: '500', fontSize: '14px', lineHeight: '22px', color: '#777777' }}
          >
            {instructionsMessages.customDescription}
          </Typography>
        </Box>
        <Switch
          checked={customInstructionsEnabled}
          onChange={() => handleSwitchChange('customInstructionsEnabled', customInstructionsEnabled)}
          sx={SXObject}
        />
      </Box>
      {customInstructionsEnabled && (
        <>
          <Divider />
          {(Object.keys(schema.fields).filter((f) => f !== 'dataGathering') as Array<keyof FormData>).map((field) => (
            <InstructionField
              key={field}
              name={field}
              control={control}
              errors={errors}
              setValue={setValue}
              trigger={trigger}
            />
          ))}
        </>
      )}
    </Box>
  )
}

export default Instructions
