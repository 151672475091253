import { FC, useRef, useState, ReactNode, MutableRefObject } from 'react'
import styles from './InviteMemberModal.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import CustomInput from 'components/customInput/CustomInput'
import { useClickOutside } from 'helpers/useClickOutside'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import { isEmailValid } from 'helpers/validation'
import IconAdd from 'assets/icons/iconAdd'
import { messages } from 'constants/messages'
import IconClose from 'assets/icons/iconClose'
import { User, UserByEmailData, UserRoles } from 'types/api'
import ChangeViewerModal from '../changeViewerModal/ChangeViewerModal'

interface iFormModal {
  title: string
  message: string
  onClose: () => void
  children?: ReactNode
  invitedUsers?: User[]
  isPortalInvites?: boolean
}

const {
  buttonsGroup,
  container,
  contentStyles,
  titleStyles,
  messageStyles,
  inputsGroup,
  inputClass,
  iconCloseContainer,
  addPersonContainer,
} = styles

const { inviteMemberModal } = messages.modals

const InviteMemberModal: FC<iFormModal> = ({ title, message, onClose, invitedUsers, isPortalInvites }) => {
  const [isChangeViewerModalVisible, setIsChangeViewerModalVisible] = useState(false)

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  useClickOutside(ref, (event: any) => onHandleClose(event))

  const user: User = useAppSelector((state) => state.user)

  const [inputs, setInputs] = useState([{ value: '', isValidEmail: true, isUnique: true }])
  const [viewers, setViewers] = useState<UserByEmailData[]>([])

  const [inviteViewers, { isLoading: isInviteUsersLoading }] = api.useInviteViewersToPortalMutation()
  const [inviteUsersToCompany, { isLoading }] = api.useInviteUsersToCompanyMutation()
  const [getUserByEmail] = api.useLazyGetUserByEmailQuery()

  const handleChangeViewerModalVisible = () => {
    setIsChangeViewerModalVisible(!isChangeViewerModalVisible)
  }

  const handleInputChange = (index: number, newValue: string) => {
    const newInputs = [...inputs]
    const isValidEmail = isEmailValid(newValue)

    const isUnique = !invitedUsers?.some((user) => user.login === newValue)

    const isNotRepeated = newInputs.some((input, i) => i !== index && input.value === newValue)

    newInputs[index].isValidEmail = isValidEmail
    newInputs[index].isUnique = isUnique && !isNotRepeated
    newInputs[index].value = newValue
    setInputs(newInputs)
  }

  const onHandleClose = (event?: any): void => {
    if (event && !event?.target.closest('[class*="InviteMemberModal"]')) return

    setInputs([{ value: '', isValidEmail: true, isUnique: true }])
    onClose()
  }

  const handleSendInvites = async () => {
    try {
      const emails = inputs.map((input) => input.value).filter(Boolean)

      if (!emails.length) {
        toastFuncError(inviteMemberModal.noValidEmailsProvided)
        return
      }

      if (isPortalInvites) {
        await inviteViewers({
          companyId: user?.company_id,
          emails,
        }).unwrap()
        toastFuncSuccess(inviteMemberModal.successInviteToast)

        onHandleClose()

        return
      }

      const users = await Promise.allSettled(
        emails.map(async (email) => {
          try {
            return await getUserByEmail({ email }).unwrap()
          } catch (error) {
            // eslint-disable-next-line no-throw-literal
            throw { email, error }
          }
        })
      )

      const allUsers = users.map((result) => {
        if (result.status === 'fulfilled') {
          return { status: 'fulfilled', user: result.value }
        } else {
          return { status: 'rejected', email: result.reason.email, error: result.reason.error }
        }
      })

      const successfulUsers = allUsers
        ?.filter((user): user is { status: 'fulfilled'; user: UserByEmailData } => user.status === 'fulfilled')
        .map((user) => user.user)

      const failedUsers = allUsers
        ?.filter((user) => user.status === 'rejected')
        .map((user) => ({ email: user.email, error: user.error }))

      if (failedUsers.length > 0) {
        const invitePromises = failedUsers.map(async ({ email, error }) => {
          const errorMessage = error?.data?.message || inviteMemberModal.errorToastText

          if (error?.data?.message === 'User by email not found') {
            try {
              await inviteUsersToCompany({
                emails: [email],
                id: user?.company_id,
              }).unwrap()
              toastFuncSuccess(inviteMemberModal.successInviteToast)
            } catch (inviteError) {
              toastFuncError(`${email}: ${inviteMemberModal.failedToInviteUser}`)
            }
          } else {
            toastFuncError(`${email}: ${errorMessage}`)
          }
        })

        await Promise.allSettled(invitePromises)
      }

      const filteredViewers = successfulUsers?.filter((user) => user.role === UserRoles.VIEWER)
      const notViewers = successfulUsers?.filter((user) => user.role !== UserRoles.VIEWER)

      if (filteredViewers.length) {
        setViewers(filteredViewers)
        setIsChangeViewerModalVisible(true)
      }

      if (notViewers.length > 0) {
        await inviteUsersToCompany({
          emails: notViewers.map((input) => input.email),
          id: user?.company_id,
        }).unwrap()

        toastFuncSuccess(inviteMemberModal.successInviteToast)
      }

      if (!filteredViewers.length) {
        onHandleClose()
      }
    } catch (error: unknown) {
      const errorData = error as { data?: { message?: string } }

      toastFuncError(errorData?.data?.message || inviteMemberModal.errorToastText)
    }
  }

  const areInputsValid = (): boolean => {
    const areValuesNotEmpty = inputs.every(({ value }) => value !== '')

    const areAllValid = inputs.every(({ isValidEmail }) => isValidEmail !== false)

    const areAllUnique = inputs.every(({ isUnique }) => isUnique !== false)

    return areValuesNotEmpty && areAllValid && areAllUnique
  }

  const handleAddAnotherPerson = (): void => {
    setInputs((prevInputs) => [...prevInputs, { value: '', isValidEmail: true, isUnique: true }])
  }

  const removePerson = (index: number): void => {
    setInputs((prevInputs) => {
      const valueOfRemovedInput = prevInputs[index].value
      const repeatedValueCount = prevInputs.filter((input) => input.value === valueOfRemovedInput).length
      const isValueInInvitedUsers = invitedUsers?.some((user) => user.login === valueOfRemovedInput)

      return [
        ...prevInputs.filter((_, i) => {
          if (
            _.value === valueOfRemovedInput &&
            _.isUnique === false &&
            repeatedValueCount < 3 &&
            !isValueInInvitedUsers
          ) {
            _.isUnique = true
          }
          return i !== index
        }),
      ]
    })
  }

  return (
    <>
      <div className={container}>
        <div className={contentStyles} ref={ref}>
          <div className={titleStyles}>{title}</div>
          <div className={messageStyles}>{message}</div>
          <div className={inputsGroup}>
            {inputs.map((input, index) => (
              <div className={inputClass} key={index}>
                <CustomInput
                  value={input.value || ''}
                  placeholder={inviteMemberModal.emailPlaceholder}
                  onChangeValue={(value) => handleInputChange(index, value)}
                  isInvalid={!input.isValidEmail || !input.isUnique}
                  textError={!input.isUnique ? inviteMemberModal.notUniqueError : inviteMemberModal.inputErrorMessage}
                />
                {index !== 0 && (
                  <div className={iconCloseContainer} onClick={() => removePerson(index)}>
                    <IconClose />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={addPersonContainer}>
            <ActionButton
              label={inviteMemberModal.addAnotherPerson}
              icon={<IconAdd />}
              onPress={handleAddAnotherPerson}
              buttonType={ActionButtonType.PRIMARY}
              size={ActionButtonSize.SMALL}
            />
          </div>
          <div className={buttonsGroup}>
            <ActionButton
              label={inviteMemberModal.cancelButton}
              onPress={onHandleClose}
              buttonType={ActionButtonType.TERTIARY}
              size={ActionButtonSize.MEDIUM}
            />
            <ActionButton
              label={inviteMemberModal.sendInviteButton}
              onPress={handleSendInvites}
              buttonType={ActionButtonType.PRIMARY}
              size={ActionButtonSize.MEDIUM}
              disabled={!areInputsValid()}
              loading={isLoading || isInviteUsersLoading}
            />
          </div>
        </div>
      </div>
      {isChangeViewerModalVisible ? (
        <ChangeViewerModal
          isOpen={isChangeViewerModalVisible}
          emails={viewers.map((input) => input.email)}
          handleVisible={handleChangeViewerModalVisible}
          isInviteUsersToCompanyLoading={isLoading}
          onHandleInviteMemberModalClose={onHandleClose}
        />
      ) : null}
    </>
  )
}

export default InviteMemberModal
