import { FC } from 'react'

const IconDefaultAvatar: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4126_29276)">
        <circle cx="20" cy="20" r="20" fill="#DCDCDC" />
        <circle cx="20" cy="17" r="8" fill="#F6F6F6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5546 32.5745C31.888 37.1041 26.2827 40 20.0012 40C13.7196 40 8.11436 37.1041 4.44775 32.5745C8.78518 30.3295 14.1689 29 20.0012 29C25.8334 29 31.2171 30.3295 35.5546 32.5745Z"
          fill="#F6F6F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4126_29276">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconDefaultAvatar
