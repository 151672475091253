import { FC } from 'react'

interface iIconChecked {
  fillColor?: string
}

const IconChecked: FC<iIconChecked> = ({ fillColor = '#909090' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1083_5274"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1083_5274)">
        <path
          d="M10.6 13.7462L8.27688 11.4231C8.13842 11.2846 7.96439 11.2138 7.75478 11.2106C7.54518 11.2074 7.36794 11.2782 7.22308 11.4231C7.07819 11.5679 7.00575 11.7436 7.00575 11.95C7.00575 12.1564 7.07819 12.332 7.22308 12.4769L9.9673 15.2211C10.1481 15.4019 10.359 15.4923 10.6 15.4923C10.841 15.4923 11.0519 15.4019 11.2327 15.2211L16.7961 9.65765C16.9346 9.5192 17.0054 9.34517 17.0086 9.13555C17.0118 8.92593 16.941 8.74869 16.7961 8.60383C16.6513 8.45896 16.4756 8.38653 16.2692 8.38653C16.0628 8.38653 15.8872 8.45896 15.7423 8.60383L10.6 13.7462ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default IconChecked
