const IconSearch = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2671_66"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2671_66)">
      <path
        d="M7.93271 13.0127C6.50966 13.0127 5.30452 12.5192 4.31729 11.5321C3.33021 10.5449 2.83667 9.33972 2.83667 7.91666C2.83667 6.4936 3.33021 5.28847 4.31729 4.30124C5.30452 3.31416 6.50966 2.82062 7.93271 2.82062C9.35577 2.82062 10.5609 3.31416 11.5481 4.30124C12.5352 5.28847 13.0288 6.4936 13.0288 7.91666C13.0288 8.5118 12.9289 9.0802 12.7292 9.62187C12.5293 10.1635 12.2627 10.6346 11.9294 11.0352L16.7244 15.8302C16.8398 15.9455 16.8988 16.0905 16.9015 16.2652C16.9041 16.4399 16.8451 16.5876 16.7244 16.7083C16.6037 16.829 16.4573 16.8894 16.2852 16.8894C16.1133 16.8894 15.9669 16.829 15.8463 16.7083L11.0513 11.9133C10.6346 12.2574 10.1554 12.5266 9.61375 12.721C9.07209 12.9155 8.51174 13.0127 7.93271 13.0127ZM7.93271 11.7629C9.00646 11.7629 9.91591 11.3903 10.661 10.645C11.4063 9.89985 11.779 8.99041 11.779 7.91666C11.779 6.84291 11.4063 5.93346 10.661 5.18833C9.91591 4.44305 9.00646 4.07041 7.93271 4.07041C6.85896 4.07041 5.94952 4.44305 5.20438 5.18833C4.4591 5.93346 4.08646 6.84291 4.08646 7.91666C4.08646 8.99041 4.4591 9.89985 5.20438 10.645C5.94952 11.3903 6.85896 11.7629 7.93271 11.7629Z"
        fill="#777777"
      />
    </g>
  </svg>
)

export default IconSearch
