import { useForm, Controller } from 'react-hook-form'
import { ChangeEvent, useEffect } from 'react'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import styles from '../../TechBotDataSources.module.scss'
import CustomLoader from 'components/customLoader/CustomLoader'
import { messages } from 'constants/messages'
import CustomInput from 'components/customInput/CustomInput'
import { handleURL } from 'helpers/handleURL'
import IconAdd from 'assets/icons/iconAdd'
import { useSelector } from 'react-redux'
import { botSelector } from 'redux/bot/slice'
import { useLinks } from 'helpers/useLinks'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { websitesSchema } from './schema'
import PaginationComponent from 'components/pagination/PaginationComponent'
import DataTable from './DataTable'
import { Checkbox, FormControlLabel } from '@mui/material'
import Tooltip from 'components/tooltip/Tooltip'
import IconChecked from 'assets/icons/iconChecked'

const { urlRow, checkbox } = styles
const { techBotDataSources } = messages.techbots

type FormValues = InferType<typeof websitesSchema>

const Websites = () => {
  const bot = useSelector(botSelector)
  const botId = bot?.id || ''

  const {
    onHandleAddLink,
    onHandleRemoveLink,
    isLinksLoading,
    isAddingWebsite,
    addedLinks,
    totalPages,
    page,
    handlePageChange,
    websites,
    stateCheckbox,
    setStateCheckbox,
  } = useLinks(botId)

  useEffect(() => {
    localStorage.setItem('includePDFs', stateCheckbox.toString())
  }, [stateCheckbox])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStateCheckbox(event.target.checked)
  }

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    resolver: yupResolver(websitesSchema),
  })

  const urlValue = watch('url')

  const onSubmit = (data: FormValues) => {
    const url = handleURL(data.url)
    onHandleAddLink(url)
    reset({ url: '' })
  }

  return (
    <>
      {isLinksLoading && <CustomLoader />}
      <form className={urlRow} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <CustomInput
              id="url"
              value={field.value}
              onChangeValue={field.onChange}
              labelVisible={true}
              label={techBotDataSources.enterUrl}
              placeholder={techBotDataSources.urlPlaceholder}
              fullWidth={true}
              onPressEnter={handleSubmit(onSubmit)}
            />
          )}
        />
        <ActionButton
          label={techBotDataSources.addLink}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          icon={<IconAdd />}
          disabled={isAddingWebsite || !urlValue}
          onPress={handleSubmit(onSubmit)}
        />
      </form>
      <div className={checkbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={stateCheckbox}
              onChange={handleChange}
              color="primary"
              checkedIcon={<IconChecked fillColor="#5E36FF" />}
            />
          }
          label={techBotDataSources.includePdf}
          sx={{
            '.MuiFormControlLabel-label': {
              color: '#777777',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '-0.4px',
            },
          }}
        />
        <Tooltip content={techBotDataSources.tooltip} />
      </div>

      <DataTable data={addedLinks || []} onRemove={onHandleRemoveLink} />
      {(websites?.total ?? 0) >= 1 && totalPages > 1 && (
        <PaginationComponent totalPages={totalPages} page={page} handlePageChange={handlePageChange} />
      )}
    </>
  )
}

export default Websites
