import { Box, Dialog } from '@mui/material'
import IconCheck from 'assets/icons/iconCheck'

import style from './RolesModal.module.scss'
const { title, list } = style

interface IProps {
  isOpen: boolean
  handleVisible: () => void
}

const RolesModal = ({ isOpen, handleVisible }: IProps) => {
  const data = [
    {
      id: 1,
      title: 'Expert',
      description:
        'Experts are team members, who can access the inbox. When viewers or public users needs human help, they can reach to experts.',
      list: [
        { id: '10', exist: true, text: 'Access to Portal' },
        { id: '11', exist: true, text: 'Access Inbox' },
        { id: '12', exist: true, text: 'Reply to support requests' },
        { id: '13', exist: true, text: 'Access Admin Panel' },
        { id: '14', exist: false, text: 'Team Management' },
        { id: '15', exist: false, text: 'Change Data Sources' },
        { id: '16', exist: false, text: 'Modify Portal' },
      ],
    },
    {
      id: 2,
      title: 'Admin',
      description:
        'Admins can access/modify/remove knowledge for training. They also manage team: invite and remove team members.',
      list: [
        { id: '20', exist: true, text: 'Access to Portal' },
        { id: '21', exist: true, text: 'Access Inbox' },
        { id: '22', exist: true, text: 'Reply to support requests' },
        { id: '23', exist: true, text: 'Access Admin Panel' },
        { id: '24', exist: true, text: 'Team Management' },
        { id: '25', exist: true, text: 'Change Data Sources' },
        { id: '26', exist: true, text: 'Modify Portal' },
      ],
    },
  ]

  return (
    <Dialog
      open={isOpen}
      onClose={handleVisible}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '468px',
          padding: '48px',
          borderRadius: '16px',
          boxShadow: 'none',
        },
      }}
    >
      <Box
        sx={{
          padding: '16px',
          borderRadius: '16px',
          backgroundColor: '#EFEBFF',
          fontFamily: 'Manrope',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '22px',
          letterSpacing: '-0.5px',
        }}
      >
        <p style={{ marginBottom: '4px', fontWeight: 'bold' }}>About Roles:</p>
        <p style={{ color: '#777777' }}>
          Roles define privileges for your team members. You can change your roles based on your needs and level access
          you want for your team. In SupportX we have two roles:
        </p>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
          width: '100%',
        }}
      >
        {data?.map((item) => (
          <Box
            key={item.id}
            sx={{
              width: '50%',
              fontFamily: 'Manrope',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            <p className={title}>{item.title}</p>
            <Box
              sx={{
                padding: '16px',
                border: '1px solid #DCDCDC',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
              }}
            >
              {item.description}
            </Box>
            <ul className={list}>
              {item.list.map((listItem) => (
                <li key={listItem.id || listItem.text}>
                  <IconCheck fillColor={listItem.exist ? '#777777' : '#f6f6f6'} />
                  {listItem.text}
                </li>
              ))}
            </ul>
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}

export default RolesModal
