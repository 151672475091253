import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './redux/store'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import './styles/index.scss'

dayjs.extend(utc)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
