interface IProps {
  fill?: string
}

const IconDownload = ({ fill = '#686868' }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask
        id="mask0_4925_14397"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4925_14397)">
        <path
          d="M12 15.2376C11.8795 15.2376 11.7673 15.2184 11.6634 15.18C11.5596 15.1415 11.4609 15.0755 11.3673 14.9819L8.2577 11.8723C8.11925 11.7339 8.04842 11.5598 8.04522 11.3502C8.04201 11.1406 8.11283 10.9634 8.2577 10.8185C8.40257 10.6736 8.58077 10.5986 8.7923 10.5935C9.00383 10.5884 9.18204 10.6582 9.32692 10.8031L11.25 12.7262V5.07615C11.25 4.86333 11.3218 4.68513 11.4654 4.54155C11.609 4.39796 11.7872 4.32617 12 4.32617C12.2128 4.32617 12.391 4.39796 12.5346 4.54155C12.6782 4.68513 12.7499 4.86333 12.7499 5.07615V12.7262L14.673 10.8031C14.8115 10.6646 14.9881 10.5964 15.2028 10.5983C15.4176 10.6002 15.5974 10.6736 15.7422 10.8185C15.8871 10.9634 15.9595 11.139 15.9595 11.3454C15.9595 11.5518 15.8871 11.7274 15.7422 11.8723L12.6327 14.9819C12.5391 15.0755 12.4403 15.1415 12.3365 15.18C12.2327 15.2184 12.1205 15.2376 12 15.2376ZM6.3077 19.4992C5.80257 19.4992 5.375 19.3242 5.025 18.9742C4.675 18.6242 4.5 18.1966 4.5 17.6915V15.7492C4.5 15.5364 4.5718 15.3582 4.7154 15.2146C4.85898 15.071 5.03718 14.9992 5.25 14.9992C5.46282 14.9992 5.64102 15.071 5.7846 15.2146C5.92818 15.3582 5.99997 15.5364 5.99997 15.7492V17.6915C5.99997 17.7684 6.03202 17.839 6.09612 17.9031C6.16024 17.9672 6.23077 17.9992 6.3077 17.9992H17.6922C17.7692 17.9992 17.8397 17.9672 17.9038 17.9031C17.9679 17.839 18 17.7684 18 17.6915V15.7492C18 15.5364 18.0718 15.3582 18.2154 15.2146C18.3589 15.071 18.5371 14.9992 18.75 14.9992C18.9628 14.9992 19.141 15.071 19.2845 15.2146C19.4281 15.3582 19.5 15.5364 19.5 15.7492V17.6915C19.5 18.1966 19.325 18.6242 18.975 18.9742C18.625 19.3242 18.1974 19.4992 17.6922 19.4992H6.3077Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default IconDownload
