import { Typography } from '@mui/material'
import { themeColors } from 'styles/colors'

interface HighlightProps {
  text: string
  word: string
}

const HighlightedText = ({ text, word }: HighlightProps) => {
  const highlightWord = (text: string, word: string): JSX.Element[] => {
    const regex = new RegExp(`(${word})`, 'gi')
    const parts = text.split(regex)

    return parts.map((part, index) =>
      part.toLowerCase() === word.toLowerCase() ? (
        <mark key={index} style={{ color: themeColors.accentViolet, backgroundColor: themeColors.lightViolet }}>
          {part}
        </mark>
      ) : (
        <span key={index}>{part}</span>
      )
    )
  }

  return (
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: 500,
        color: themeColors.darkGray,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {highlightWord(text, word)}
    </Typography>
  )
}

export default HighlightedText
