import { FileStatus, IUploadedFile, IUploadedFolder } from 'types/api'
import { MenuItem, Typography } from '@mui/material'
import IconDelete from 'assets/icons/iconDelete'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'

import styles from './DataTable.module.scss'
import { useState } from 'react'
import { Breadcrumbs } from '../Files'
import { MoveTo } from '../MoveTo'
import { FileActionsMenu } from '../FileActionsMenu'
import { messages } from 'constants/messages'
import formatFileSize from 'helpers/formatFileSize'
import { getFileStatus } from '../helpers'
import HighlightedText from 'components/highlightedText/HighlightedText'
import { themeColors } from 'styles/colors'

const { tdPreview, previewContainer, grayText, cellMore, info } = styles

interface IProps {
  file: IUploadedFile
  breadcrumbs: Breadcrumbs
  pathOfBreadcrumbs: string
  searchValue?: string
}

const FileTr = ({ file, breadcrumbs, pathOfBreadcrumbs, searchValue }: IProps) => {
  const text = messages.files
  const dispatch = useDispatch()

  const dateObj = new Date(file.created_at)
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObj.getUTCFullYear()
  const formattedDate = `${month}/${day}/${year}`

  const [selectedMoveToFile, setSelectedMoveToFile] = useState<IUploadedFile | IUploadedFolder | null>(null)
  const handleMoveTo = (file: IUploadedFile) => {
    setSelectedMoveToFile(file)
  }

  const handleOpenDeleteFileModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FILE,
        fileId: file.id,
        fileName: file.name,
      })
    )
  }

  return (
    <>
      {selectedMoveToFile && (
        <MoveTo selectedEntry={selectedMoveToFile} setSelectedEntry={setSelectedMoveToFile} currentPath={breadcrumbs} />
      )}
      {file && (
        <tr key={file.id}>
          <td className={tdPreview}>
            <div className={previewContainer}>
              <a href={file.download_url || file.s3_url} target="_blank" rel="noreferrer">
                <span>.{file.name.split('.').pop()}</span>
              </a>
            </div>
            <div className={info}>
              {searchValue ? (
                <HighlightedText text={file.name} word={searchValue} />
              ) : (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    letterSpacing: '-0.4px',
                    color: themeColors.darkGray,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.name}
                </Typography>
              )}
              <p className={grayText}>{!file.size ? text.unknownSize : formatFileSize(file.size)}</p>
            </div>
          </td>
          <td>{formattedDate}</td>
          <td>{file.name.split('.').pop()}</td>
          <td>{file.created_by}</td>
          <td>{getFileStatus(file.status)}</td>
          <td className={cellMore}>
            {file.status === FileStatus.FAILED ? (
              <MenuItem onClick={handleOpenDeleteFileModal}>
                <IconDelete fillColor="red" />
              </MenuItem>
            ) : (
              <FileActionsMenu file={file} handleMoveTo={handleMoveTo} currentPath={pathOfBreadcrumbs} />
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export default FileTr
