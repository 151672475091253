import { FC } from 'react'
import styles from './ActionButton.module.scss'
import IconLoader from 'assets/icons/loader/iconLoader'
import classNames from 'classnames'
import { iActionButton } from './types'
import { Box } from '@mui/material'

export enum ActionButtonSize {
  LARGE = 'button__large',
  MEDIUM = 'button__medium',
  MEDIUM_WIDE = 'button__medium-wide',
  SMALL = 'button__small',
}

export enum ActionButtonType {
  PRIMARY = 'button__primary',
  SECONDARY = 'button__secondary',
  TERTIARY = 'button__tertiary',
  WARNING = 'button__warning',
  INFO = 'button__info',
  TRANSPARENT = 'button__transparent',
}

const { buttonStyles, separator } = styles

const ActionButton: FC<iActionButton> = ({
  label,
  onPress,
  disabled = false,
  loading = false,
  buttonType = ActionButtonType.PRIMARY,
  addSeparator = false,
  icon,
  dataTestid,
  size,
  style,
  styleBtn,
}) => {
  const onHandlePress = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (onPress && !loading) {
      onPress()
    }
  }

  const btnClass = classNames({
    [buttonStyles]: true,
    [styles[buttonType]]: true,
    [styles[size || '']]: size,
    [separator]: addSeparator,
  })

  const isIcon = typeof icon === 'object'

  return (
    <button
      className={btnClass}
      type="submit"
      onClick={onHandlePress}
      disabled={disabled}
      data-testid={dataTestid}
      style={styleBtn}
    >
      <>
        {loading ? (
          <Box
            sx={{
              marginRight: '8px',
            }}
          >
            <IconLoader fillColor={`${buttonType === ActionButtonType.SECONDARY && !disabled ? '#5e36ff' : 'white'}`} />
          </Box>
        ) : (
          isIcon && icon
        )}
        <p style={style}>{label}</p>
      </>
    </button>
  )
}

export default ActionButton
