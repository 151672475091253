import { useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import styles from './PortalCustomization.module.scss'
import CustomInput from 'components/customInput/CustomInput'
import UploadLogoInput from '../uploadLogoInput/UploadLogoInput'
import ColorPicker from '../colorPicker/ColorPicker'
import { toastFuncSuccess, toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { api } from 'redux/rtkQuery'
import { UpdateBotData } from 'types/api'
import { logger } from 'utils/logger'
import { messages } from 'constants/messages'
import { useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'
import PortalPreviewIcon from 'assets/icons/portalPreviewIcon'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import IconArrowDown from 'assets/icons/iconArrowDown'
import { Note } from 'components/noteComponent/Note'

const { portalCustomization } = messages.portal
const { container, content, formStyles, title, label, description, preview } = styles

const PortalCustomization = () => {
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data, isLoading } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = data?.id || ''

  const { data: bot, isLoading: isGettingBot } = api.useGetBotByIdQuery(
    { id: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      logo: bot?.portal_logo_url || '',
      primaryColor: bot?.portal_primary_color || '#5E36FF',
      initialMessage: bot?.portal_initial_message || '',
      subMessage: bot?.portal_sub_message || '',
    },
  })

  const { logo, primaryColor, initialMessage, subMessage } = watch()

  const hasDataChanged =
    primaryColor !== (bot?.portal_primary_color || '#5E36FF') ||
    initialMessage !== bot?.portal_initial_message ||
    subMessage !== bot?.portal_sub_message

  const [setShouldShowSaveButton, setReceivedFunction, setIsDisabled] = useOutletContext<any>()

  useEffect(() => {
    if (bot) {
      setValue('logo', bot.portal_logo_url || '')
      setValue('primaryColor', bot.portal_primary_color || '#5E36FF')
      setValue('initialMessage', bot.portal_initial_message || '')
      setValue('subMessage', bot.portal_sub_message || '')
    }
  }, [bot, setValue])

  const [updateBotById] = api.useUpdateBotByIdMutation()
  const [uploadPortalLogo, { isLoading: isUploadingLogo }] = api.useUploadPortalLogoMutation()

  const saveBotData = useCallback(
    async (botId: string, data: UpdateBotData) => {
      try {
        const updateRes = await updateBotById({ id: botId, body: data })
        if (!('data' in updateRes)) {
          throw new Error('Save supportX data error')
        }
      } catch (error) {
        logger.error('saveBotData error', error)
        toastFuncError(
          error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Failed to save supportX data'
        )
        throw error
      }
    },
    [updateBotById]
  )

  const onHandleSaveChanges = useCallback(async () => {
    try {
      await saveBotData(botId, {
        portal_logo_url: getValues('logo'),
        portal_primary_color: getValues('primaryColor'),
        portal_initial_message: getValues('initialMessage'),
        portal_sub_message: getValues('subMessage'),
      })
      toastFuncSuccess(portalCustomization.saveChangesSuccess)
    } catch (error) {
      logger.error(`error`, error)
    }
  }, [botId, getValues, saveBotData])

  const handleFileUpload = async (file: File, type: 'logo') => {
    if (!file) return
    try {
      if (type === 'logo') {
        await uploadPortalLogo({ id: botId, file }).unwrap()
        toastFuncSuccess(portalCustomization.uploadLogoSuccess)
      }
    } catch {
      toastFuncError(portalCustomization.uploadFailed)
    }
  }

  useEffect(() => {
    const isEmptyMessage = initialMessage.trim() === '' || subMessage.trim() === ''
    const isLimit = initialMessage.length === 115 || subMessage.length === 223

    setShouldShowSaveButton(hasDataChanged)
    setIsDisabled(isEmptyMessage || isLimit)
  }, [hasDataChanged, initialMessage, subMessage, setShouldShowSaveButton, setIsDisabled])

  useEffect(() => {
    setReceivedFunction(() => onHandleSaveChanges)
    return () => setReceivedFunction(null)
  }, [onHandleSaveChanges, setReceivedFunction])

  if (isLoading || !bot || isGettingBot) return <CustomLoader />

  return (
    <>
      <div className={container}>
        <div className={content}>
          <div className={formStyles}>
            <Accordion
              sx={{
                width: '100%',
                maxWidth: '488px',
                boxShadow: 'none',
                borderBottom: '0px',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                id="panel1"
                aria-controls="panel1"
                expandIcon={<IconArrowDown />}
                sx={{
                  borderBottom: '1px solid #DCDCDC',
                  '&.Mui-expanded': {
                    margin: '0px',
                    height: '48px',
                    minHeight: '48px',
                  },
                }}
              >
                <Typography className={title}>{portalCustomization.introduceYourBrand}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: '0px',
                }}
              >
                <p className={label}>{portalCustomization.uploadWebsiteLogo}</p>
                <p className={description}>{portalCustomization.uploadWebsiteLogoDescription}</p>
                <Controller
                  name="logo"
                  control={control}
                  render={({ field }) => (
                    <UploadLogoInput
                      htmlfor={portalCustomization.uploadWebsiteLogo}
                      logoPreview={field.value}
                      setLogoPreview={(url) => setValue('logo', url)}
                      onChangeLogo={(file) => handleFileUpload(file, 'logo')}
                      isUploading={isUploadingLogo}
                    />
                  )}
                />
                <p className={label}>{portalCustomization.addPrimaryBrandColors}</p>
                <p className={description}>{portalCustomization.addPrimaryBrandColorDescription}</p>
                <Controller
                  name="primaryColor"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker color={field.value} onChangeColor={(color) => setValue('primaryColor', color)} />
                  )}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                width: '100%',
                maxWidth: '488px',
                minHeight: '48px',
                boxShadow: 'none',
                borderBottom: '0px',
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0px',
                },
              }}
            >
              <AccordionSummary
                id="panel2"
                aria-controls="panel2"
                expandIcon={<IconArrowDown />}
                sx={{
                  borderBottom: '1px solid #DCDCDC',
                  '&.Mui-expanded': {
                    margin: '0px',
                    height: '48px',
                    minHeight: '48px',
                  },
                }}
              >
                <Typography className={title}>{portalCustomization.customMessages}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: '0px',
                  marginTop: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Note title={portalCustomization.noteTitle} description={portalCustomization.noteDescription} />
                <Controller
                  name="initialMessage"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      id="initial-message"
                      value={field.value}
                      onChangeValue={(val) => {
                        setValue('initialMessage', val)
                        trigger('initialMessage')
                      }}
                      labelVisible={true}
                      label={portalCustomization.initialMessage}
                      placeholder={portalCustomization.initialMessagePlaceholder}
                      isInvalid={!!errors.initialMessage}
                      textError={portalCustomization.initialMessageError}
                      onBlur={() => trigger('initialMessage')}
                      isRequired
                      multiline={true}
                      characterLimit={115}
                      fullWidth={true}
                      style={{
                        width: '100%',
                        maxWidth: '488px',
                        marginBottom: '10px',
                      }}
                      styleLabel={{ transform: 'translate(0, -23px)', color: '#353535' }}
                      styleTextarea={{
                        height: '48px',
                        padding: '10px 16px',
                      }}
                      styleRequired={{ left: '16px', bottom: '-10px' }}
                    />
                  )}
                />
                <Controller
                  name="subMessage"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      id="sub-message"
                      value={field.value}
                      onChangeValue={(val) => {
                        setValue('subMessage', val)
                        trigger('subMessage')
                      }}
                      labelVisible={true}
                      label={portalCustomization.subMessage}
                      placeholder={portalCustomization.subMessagePlaceholder}
                      isInvalid={!!errors.subMessage}
                      textError={portalCustomization.subMessageError}
                      onBlur={() => trigger('subMessage')}
                      isRequired
                      multiline={true}
                      characterLimit={223}
                      fullWidth={true}
                      style={{
                        width: '100%',
                        maxWidth: '488px',
                      }}
                      styleLabel={{ transform: 'translate(0, -23px)', color: '#353535' }}
                      styleTextarea={{
                        height: '48px',
                        padding: '10px 16px',
                      }}
                      styleRequired={{ left: '16px', bottom: '-10px' }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={preview}>
          <PortalPreviewIcon
            initialMessage={
              initialMessage
                ? initialMessage.length > 60
                  ? `${initialMessage.slice(0, 60)}...`
                  : initialMessage
                : undefined
            }
            subMessage={
              subMessage ? (subMessage.length > 69 ? `${subMessage.slice(0, 69)}...` : subMessage) : undefined
            }
            primaryFillColor={primaryColor}
            image={logo}
          />
        </div>
      </div>
    </>
  )
}

export default PortalCustomization
