import IconLoader from 'assets/icons/loader/iconLoader'
import { FC } from 'react'
import styles from './DataSourcesStatus.module.scss'
import IconCheckedSuccess from 'assets/icons/iconCheckedSuccess'
import { messages } from 'constants/messages'
import { IconDoubleArrowUp } from 'assets/icons/iconDoubleArrowUp'
import { themeColors } from 'styles/colors'

const { statusContainer, statusLoading, statusDescription, status, statusToUpdate, statusLastUpdate } = styles

type DataSourcesStatusProps = {
  isRetraining: boolean
  shouldRebuildKnowledge: boolean
  updatedAt: string
}

const { updating, updateAvaliable, upToDate, lastUpdate } = messages.techbots.dataSourcesStatus

const DataSourcesStatus: FC<DataSourcesStatusProps> = ({ isRetraining, shouldRebuildKnowledge, updatedAt }) => {
  return (
    <div className={statusContainer}>
      {isRetraining && (
        <div className={statusLoading}>
          <IconLoader fillColor={themeColors.accentViolet} />
          <p className={statusDescription}>{updating}</p>
        </div>
      )}
      {shouldRebuildKnowledge && !isRetraining && (
        <div className={statusToUpdate}>
          <IconDoubleArrowUp />
          <p className={statusDescription}>{updateAvaliable}</p>
        </div>
      )}
      {!isRetraining && !shouldRebuildKnowledge && (
        <div className={status}>
          <IconCheckedSuccess fillColor={themeColors.mainGreen} />
          <p className={statusDescription}>{upToDate}</p>
        </div>
      )}
      <p className={statusLastUpdate}>
        {lastUpdate} {updatedAt}
      </p>
    </div>
  )
}

export default DataSourcesStatus
