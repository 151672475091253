import { FileStatus } from 'types/api'

export const getFileStatus = (status: FileStatus | undefined) => {
  switch (status) {
    case FileStatus.PROCESSING:
      return 'Processing'
    case FileStatus.IN_SYSTEM:
      return 'Uploaded'
    case FileStatus.FAILED:
      return 'Failed to process'
    default:
      return 'Uploaded'
  }
}
