import { Suspense, lazy, ReactNode, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import styles from './styles/App.module.scss'
import {
  AUTH_REDIRECT,
  DATA_GATHERED_URL,
  CONVERSATIONS_URL,
  PROFILE_URL,
  FORGOT_PASSWORD_URL,
  INVITE_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
  ROOT_URL,
  SETTINGS_URL,
  SIGN_UP_URL,
  SUBSCRIPTION_URL,
  TEAM_MANAGEMENT_URL,
  PLAYGROUND_URL,
  SETUP_URL,
  WIDGET_PLAYGROUND_URL,
  WIDGET_CUSTOMIZE_URL,
  WIDGET_CONFIGURATION_URL,
  DATA_SOURCES_URL,
  WIDGET_INSTALLATION_URL,
  SETTINGS_HANDOFF_URL,
  PORTAL_PREVIEW_URL,
  PORTAL_CUSTOMIZE_URL,
  SETTINGS_INTEGRATION_URL,
  BUY_SUBSCRIPTION_URL,
  DATA_SOURCES_SELF_LEARNING_URL,
  DATA_SOURCES_SNIPPETS_URL,
  DATA_SOURCES_IMAGES_URL,
  DATA_SOURCES_WEBSITES_URL,
  DATA_SOURCES_FILES_URL,
  CONTACT_SALES_FORM_URL,
  DATA_SOURCES_DRIVES_URL,
  DATA_SOURCES_LIVE_SEARCH_URL,
  SETTINGS_INSTRUCTIONS_URL,
} from 'utils/constant'
import { ProtectForAuthUser } from 'components/ProtectForAuthUser'
import { UserRoles } from 'types/api'
import { ProtectedFor } from 'components/ProtectedFor'
import CustomLoader from 'components/customLoader/CustomLoader'
import { Playground } from 'modules/playground/playground'
import TechBotTest from 'modules/techbots/components/techBotTest/TechBotTest'
import TechBotBrandForm from 'modules/techbots/components/techBotBrandForm/TechBotBrandForm'
import TechBotDetails from 'modules/techbots/components/techBotDetails/TechBotDetails'
import TechBotDataSources from 'modules/techbots/components/techBotDataSources/TechBotDataSources'
import TechBotDeploy from 'modules/techbots/components/techBotDeploy/TechBotDeploy'
import Handoff from 'modules/techbots/components/handoff/Handoff'
import PortalPreview from 'modules/techbots/components/portalPreview/PortalPreview'
import PortalCustomization from 'modules/techbots/components/portalCustomization/PortalCustomization'
import ExternalDrives from 'modules/techbots/components/techBotDataSources/components/externalDrives/ExternalDrives'
import Integrations from 'modules/integrations/Integrations'
import { Box } from '@mui/material'
import packageJson from '../package.json'
import SelfLearning from 'modules/techbots/components/techBotDataSources/components/selfLearning/SelfLearning'
import Knowledge from 'modules/knowledge/views/Knowledge'
import Images from 'modules/images/Images'
import Websites from 'modules/techbots/components/techBotDataSources/components/websites/Websites'
import Files from 'modules/techbots/components/techBotDataSources/components/files/Files'
import SalesContactForm from 'modules/salesContactForm/SalesContactForm'
import ModalManager from 'modules/modal/ModalManager'
import LiveSearch from 'modules/techbots/components/techBotDataSources/components/liveSearch/LiveSearch'
import { theme } from 'mui/theme'
import Instructions from 'modules/techbots/components/instructions/Instructions'

const componentPaths = {
  AuthWrapper: () => import('modules/authWrapper/views/AuthWrapper'),
  ChangeSubscription: () => import('modules/subscription/views/ChangeSubscriptionView'),
  Conversations: () => import('modules/conversations/views/Conversations'),
  CustomToastContainer: () => import('components/customToastContainer/CustomToastContainer'),
  Dashboard: () => import('modules/dashboard/views/Dashboard'),
  DataGathered: () => import('modules/dataGathered/DataGathered'),
  Demo: () => import('modules/demo/Demo'),
  ForgotPassword: () => import('modules/authorization/views/ForgotPassword'),
  Invite: () => import('modules/authorization/views/Invite'),
  Login: () => import('modules/authorization/views/Login'),
  Profile: () => import('modules/profile/views/Profile'),
  ResetPassword: () => import('modules/authorization/views/ResetPassword'),
  Settings: () => import('modules/settings/views/Settings'),
  SignUp: () => import('modules/authorization/views/SignUp'),
  Subscription: () => import('modules/subscription/views/Subscription'),
  TechBots: () => import('modules/techbots/views/TechBots'),
  TeamManagement: () => import('modules/teamManagement/views/TeamManagement'),
}

const Components = Object.fromEntries(
  Object.entries(componentPaths).map(([name, importFunc]) => [name, lazy(importFunc)])
)

const AuthRedirect = () => {
  useEffect(() => {
    window.close()
  }, [])

  return <div></div>
}

const { app } = styles

const App = () => (
  <div className={app}>
    <MuiThemeProvider theme={theme}></MuiThemeProvider>
    <Box
      sx={{
        display: 'none',
      }}
    >
      Version: {packageJson.version}
    </Box>
    <CustomSuspense>
      <Components.CustomToastContainer />
    </CustomSuspense>
    <BrowserRouter basename="/">
      <Routes>
        <Route
          element={
            <CustomSuspense>
              <Components.AuthWrapper />
            </CustomSuspense>
          }
        >
          <Route
            path={AUTH_REDIRECT}
            element={
              <CustomSuspense>
                <AuthRedirect />
              </CustomSuspense>
            }
          />
          <Route
            path={ROOT_URL}
            element={
              <CustomSuspense>
                <Components.Dashboard />
              </CustomSuspense>
            }
          />
          <Route
            path={SETUP_URL}
            element={
              <ProtectedFor roles={[UserRoles.ADMIN]}>
                <CustomSuspense>
                  <Components.TechBots />
                </CustomSuspense>
              </ProtectedFor>
            }
          >
            <Route
              path={PORTAL_PREVIEW_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <PortalPreview />
                </ProtectedFor>
              }
            />
            <Route
              path={PORTAL_CUSTOMIZE_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <PortalCustomization />
                </ProtectedFor>
              }
            />
            <Route
              path={WIDGET_PLAYGROUND_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <TechBotTest />
                </ProtectedFor>
              }
            />
            <Route
              path={WIDGET_CUSTOMIZE_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <TechBotBrandForm />
                </ProtectedFor>
              }
            />
            <Route
              path={WIDGET_INSTALLATION_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <TechBotDeploy />
                </ProtectedFor>
              }
            />
            <Route
              path={WIDGET_CONFIGURATION_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <TechBotDetails />
                </ProtectedFor>
              }
            />
            <Route
              path={SETTINGS_HANDOFF_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Handoff />
                </ProtectedFor>
              }
            />
            <Route
              path={SETTINGS_INTEGRATION_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Integrations />
                </ProtectedFor>
              }
            />
            <Route
              path={SETTINGS_INSTRUCTIONS_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Instructions />
                </ProtectedFor>
              }
            />
          </Route>
          <Route
            path={CONVERSATIONS_URL}
            element={
              <CustomSuspense>
                <Components.Conversations />
              </CustomSuspense>
            }
          />
          <Route
            path={DATA_SOURCES_URL}
            element={
              <ProtectedFor roles={[UserRoles.ADMIN]}>
                <TechBotDataSources />
              </ProtectedFor>
            }
          >
            <Route
              path={DATA_SOURCES_WEBSITES_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Websites />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_FILES_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Files />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_SELF_LEARNING_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <SelfLearning />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_SNIPPETS_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Knowledge />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_IMAGES_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <Images />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_DRIVES_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <ExternalDrives />
                </ProtectedFor>
              }
            />
            <Route
              path={DATA_SOURCES_LIVE_SEARCH_URL}
              element={
                <ProtectedFor roles={[UserRoles.ADMIN]}>
                  <LiveSearch />
                </ProtectedFor>
              }
            />
          </Route>
          <Route
            path={DATA_GATHERED_URL}
            element={
              <CustomSuspense>
                <Components.DataGathered />
              </CustomSuspense>
            }
          ></Route>
          {/* TODO: update and protect subscriptions url */}
          <Route
            path={SETTINGS_URL}
            element={
              <CustomSuspense>
                <Components.Settings />
              </CustomSuspense>
            }
          >
            <Route path={PROFILE_URL} element={<Components.Profile />} />
            <Route
              path={TEAM_MANAGEMENT_URL}
              element={
                <CustomSuspense>
                  <Components.TeamManagement />
                </CustomSuspense>
              }
            />
            <Route
              path={SUBSCRIPTION_URL}
              element={
                <CustomSuspense>
                  <Components.Subscription />
                </CustomSuspense>
              }
            />
          </Route>
          <Route
            path={BUY_SUBSCRIPTION_URL}
            element={
              <ProtectedFor roles={[UserRoles.ADMIN]}>
                <CustomSuspense>
                  <Components.ChangeSubscription />
                </CustomSuspense>
              </ProtectedFor>
            }
          />
          <Route
            path={CONTACT_SALES_FORM_URL}
            element={
              <ProtectedFor roles={[UserRoles.ADMIN]}>
                <SalesContactForm />
              </ProtectedFor>
            }
          />
        </Route>
        <Route
          path={SIGN_UP_URL}
          element={
            <ProtectForAuthUser>
              <CustomSuspense>
                <Components.SignUp />
              </CustomSuspense>
            </ProtectForAuthUser>
          }
        />
        <Route
          path={LOGIN_URL}
          element={
            <ProtectForAuthUser>
              <CustomSuspense>
                <Components.Login />
              </CustomSuspense>
            </ProtectForAuthUser>
          }
        />
        <Route
          path={FORGOT_PASSWORD_URL}
          element={
            <ProtectForAuthUser>
              <CustomSuspense>
                <Components.ForgotPassword />
              </CustomSuspense>
            </ProtectForAuthUser>
          }
        />
        <Route
          path={INVITE_URL}
          element={
            <ProtectForAuthUser>
              <CustomSuspense>
                <Components.Invite />
              </CustomSuspense>
            </ProtectForAuthUser>
          }
        />
        <Route
          path={`${RESET_PASSWORD_URL}/:id`}
          element={
            <CustomSuspense>
              <Components.ResetPassword />
            </CustomSuspense>
          }
        />
        <Route path={PLAYGROUND_URL} element={<Playground />} />
        <Route path="/demo/:id" element={<Components.Demo />} />
        <Route path="*" element={<Navigate to={ROOT_URL} />} />
      </Routes>
    </BrowserRouter>
    <ModalManager />
  </div>
)

export default App

const CustomSuspense = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<CustomLoader />}>{children}</Suspense>
)
