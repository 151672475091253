import { ChangeEvent, useState } from 'react'
import { IGetFilesData, IUploadedFile, IUploadedFolder, TSearchData } from 'types/api'
import { Breadcrumbs } from './Files'
import { Box } from '@mui/system'
import { Loader } from 'components/Loader'
import { Typography } from '@mui/material'
import { isFolder } from 'helpers/isFolder'
import { themeColors } from 'styles/colors'
import { FolderIcon } from 'assets/icons/iconFloder'
import { getFileFormatFromName } from 'helpers/getFileFormatFromName'
import { EmptyFolderView } from './EmptyFolderView'
import { FileActionsMenu } from './FileActionsMenu'
import { MoveTo } from './MoveTo'
import { FolderActionsMenu } from './FolderActionsMenu'
import PaginationComponent from 'components/pagination/PaginationComponent'
import formatFileSize from 'helpers/formatFileSize'
import { filterSearchDataByFolderType } from 'helpers/filterSearchDataByFolderType'
import HighlightedText from 'components/highlightedText/HighlightedText'

interface FolderViewProps {
  data: IGetFilesData | null | undefined
  searchData?: TSearchData[]
  searchValue?: string
  onChangePage: (event: ChangeEvent<unknown>, value: number) => void
  setBreadcrumbs: (folderName: string, path: string) => void
  breadcrumbs: Breadcrumbs
  pathOfBreadcrumbs: string
  handleSetIsUploadFileModalOpen: () => void
  isFetching: boolean
}

export const FolderView = ({
  data,
  searchData,
  searchValue,
  onChangePage,
  setBreadcrumbs,
  breadcrumbs,
  pathOfBreadcrumbs,
  handleSetIsUploadFileModalOpen,
  isFetching,
}: FolderViewProps) => {
  let entries = null
  const files = data?.files || []
  const folders = data?.folders || []
  const { searchFolders, searchFiles } = filterSearchDataByFolderType(searchData)
  const [selectedMoveToFile, setSelectedMoveToFile] = useState<IUploadedFile | IUploadedFolder | null>(null)
  const handleMoveTo = (entry: IUploadedFile | IUploadedFolder) => {
    setSelectedMoveToFile(entry)
  }

  if (searchData?.length) {
    entries = [...searchFolders, ...searchFiles]
  } else {
    entries = [...folders, ...files]
  }

  if (isFetching) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Loader />
      </Box>
    )
  }

  if (!files.length && !folders?.length) {
    return <EmptyFolderView handleSetIsUploadFileModalOpen={handleSetIsUploadFileModalOpen} />
  }

  return (
    <>
      {selectedMoveToFile && (
        <MoveTo selectedEntry={selectedMoveToFile} setSelectedEntry={setSelectedMoveToFile} currentPath={breadcrumbs} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {entries.map((entry) => (
          <Box
            key={entry.id}
            sx={{
              display: 'flex',
              width: '246px',
              height: '140px',
              borderRadius: '12px',
              border: '1px solid  #DCDCDC',
              flexDirection: 'column',
              padding: '16px',
            }}
          >
            <Box
              sx={{
                flexWrap: 1,
                width: '100%',
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                onClick={() => {
                  if (isFolder(entry)) {
                    setBreadcrumbs(entry.name, entry.id)
                  } else {
                    window.open(entry.download_url, '_blank')
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  height: '56px',
                  width: '56px',
                  borderRadius: '16px',
                  border: '1px solid #DCDCDC',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  svg: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                {isFolder(entry) ? <FolderIcon /> : getFileFormatFromName(entry.name)}
              </Box>
              <Box>
                {isFolder(entry) ? (
                  <FolderActionsMenu folder={entry} handleMoveTo={handleMoveTo} />
                ) : (
                  <FileActionsMenu file={entry} handleMoveTo={handleMoveTo} currentPath={pathOfBreadcrumbs} />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                mt: '8px',
                alignSelf: 'flex-start',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  maxWidth: '212px',
                }}
              >
                {searchValue && searchData?.length ? (
                  <HighlightedText text={entry.name} word={searchValue} />
                ) : (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      letterSpacing: '-0.4px',
                      color: themeColors.darkGray,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {entry.name}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '-0.4px',
                    fontWeight: 400,
                    color: themeColors.gray,
                  }}
                >
                  {isFolder(entry) ? `Files: ${entry.files_in_folder}` : formatFileSize(entry.size)}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {data && data?.totalPages > 1 && (
        <PaginationComponent totalPages={data.totalPages} page={data.page} handlePageChange={onChangePage} />
      )}
    </>
  )
}
