import DropdownMenu from 'components/dropdownMenu/DropdownMenu'
import DropdownImageContent from '../dropdownImageContent/DropdownImageContent'
import { IGetImagesResponse, ImageStatus } from 'types/api'

import styles from './ImagesTable.module.scss'
import { useState } from 'react'
import { api } from 'redux/rtkQuery'
import { botSelector } from 'redux/bot/slice'
import { useDispatch, useSelector } from 'react-redux'
import { messages } from 'constants/messages'
import IconLoader from 'assets/icons/loader/iconLoader'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'
import formatFileSize from 'helpers/formatFileSize'

const { tdPreview, previewContainer, title, grayText, switchWrapper, slider, cellMore } = styles

interface IProps {
  image: IGetImagesResponse
}

const TableTr = ({ image }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const [status, setStatus] = useState<ImageStatus>(image.status)

  const [updateImage] = api.useUpdateImageMutation()

  const handleStatusChange = async () => {
    const newStatus = status === ImageStatus.ACTIVE ? ImageStatus.INACTIVE : ImageStatus.ACTIVE
    setStatus(newStatus)

    await updateImage({
      botId: bot.id,
      imageId: image.id,
      body: {
        status: newStatus,
      },
    })
  }

  const handleImagePreviewOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.IMAGE_PREVIEW,
        s3Url: image.s3Url,
      })
    )
  }

  return (
    <>
      {status === ImageStatus.PROCESSING ? (
        <tr key={image.id}>
          <td className={tdPreview}>
            <div className={previewContainer}>
              <img
                src={image.s3Url}
                alt="preview"
                style={{
                  maskImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                  WebkitMaskImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                }}
              />
              <IconLoader fillColor="#D9D9D9" />
            </div>
            <div>
              <p className={title}>{image.fileName}</p>
              <p className={grayText}>{formatFileSize(image.fileSize)}</p>
            </div>
          </td>
          <td>{images.processing}</td>
          <td>{image.fileName.split('.').pop()}</td>
          <td>-</td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        <tr key={image.id}>
          <td className={tdPreview}>
            <div className={previewContainer}>
              <img
                src={image.s3Url}
                alt="preview"
                onClick={handleImagePreviewOpen}
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <div>
              <p>{image.fileName}</p>
              <p className={grayText}>{formatFileSize(image.fileSize)}</p>
            </div>
          </td>
          <td>
            {new Date(image.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })}
          </td>
          <td>{image.fileName.split('.').pop()}</td>
          <td>{image.category}</td>
          <td>
            <label className={switchWrapper}>
              <input
                type="checkbox"
                checked={status === ImageStatus.INACTIVE ? false : true}
                onChange={handleStatusChange}
              />
              <span className={slider}></span>
            </label>
          </td>
          <td className={cellMore}>
            <DropdownMenu>
              <DropdownImageContent
                imageId={image.id}
                fileName={image.fileName}
                title={image.title}
                description={image.description}
                s3Url={image.s3Url}
                fileSize={image.fileSize}
                status={image.status}
                category={image.category}
              />
            </DropdownMenu>
          </td>
        </tr>
      )}
    </>
  )
}

export default TableTr
