import {
  DATA_SOURCES_DRIVES_URL,
  DATA_SOURCES_FILES_URL,
  DATA_SOURCES_IMAGES_URL,
  DATA_SOURCES_LIVE_SEARCH_URL,
  DATA_SOURCES_SELF_LEARNING_URL,
  DATA_SOURCES_SNIPPETS_URL,
  DATA_SOURCES_WEBSITES_URL,
} from 'utils/constant'
import { messages } from 'constants/messages'

const { techBotDataSources } = messages.techbots

export const TECH_BOT_DATA_SOURCES_NAVIGATION = [
  {
    label: `${techBotDataSources.websites}`,
    route: DATA_SOURCES_WEBSITES_URL,
  },
  {
    label: `${techBotDataSources.files}`,
    route: DATA_SOURCES_FILES_URL,
  },
  {
    label: `${techBotDataSources.snippets}`,
    route: DATA_SOURCES_SNIPPETS_URL,
  },
  {
    label: `${techBotDataSources.selfLearning}`,
    route: DATA_SOURCES_SELF_LEARNING_URL,
  },
  {
    label: `${techBotDataSources.images}`,
    route: DATA_SOURCES_IMAGES_URL,
  },
  {
    label: `${techBotDataSources.externalDrives}`,
    route: DATA_SOURCES_DRIVES_URL,
  },
  {
    label: `${techBotDataSources.liveSearch}`,
    route: DATA_SOURCES_LIVE_SEARCH_URL,
  },
]
