import { ParsedResourceLink, ResourceLinkStatus } from 'types/api'
import IconDelete from 'assets/icons/iconDelete'
import { handelShowError } from 'helpers/handelShowError'
import styles from './DataTable.module.scss'
import { messages } from 'constants/messages'
import IconLink from 'assets/icons/iconLink'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconUpdate from 'assets/icons/iconUpdate'
import IconLoader from 'assets/icons/loader/iconLoader'
import IconFailed from 'assets/icons/iconFailed'
import IconReload from 'assets/icons/iconReload'
import dayjs from 'dayjs'
import { api } from 'redux/rtkQuery'
import { useSelector } from 'react-redux'
import { botSelector } from 'redux/bot/slice'

const { tdPreview, buttonIcon, buttonIconReload, cellMore, buttonsWrapper } = styles

interface IProps {
  website: ParsedResourceLink
  onDelete: (item: ParsedResourceLink) => unknown
}

const FileTr = ({ website, onDelete }: IProps) => {
  const { techBotDataSources } = messages.techbots
  const bot = useSelector(botSelector)

  const [processWebsite, { isLoading }] = api.useProcessWebsiteMutation()

  const formattedDate = website?.last_processed_at
    ? dayjs.utc(website.last_processed_at).local().format('MM/DD/YYYY HH:mm')
    : '-'

  const handleProcessWebsite = async () => {
    try {
      await processWebsite({ botId: bot?.id || '', websiteId: website.id }).unwrap()
    } catch (error) {
      handelShowError(error)
    }
  }

  return (
    <>
      {website && (
        <tr key={website.id}>
          <td className={tdPreview}>
            <IconLink />
            <p>{website.url}</p>
          </td>
          <td>{website.found_links || '-'}</td>
          <td>{website.found_images || '-'}</td>
          <td>{website.found_pdfs || '-'}</td>
          <td>{'bot_id' in website ? formattedDate : '-'}</td>
          <td>
            {website.bot_id ? (
              [ResourceLinkStatus.ADDED, ResourceLinkStatus.FINISHED].includes(website.status) ? (
                <ActionButton
                  size={ActionButtonSize.SMALL}
                  buttonType={ActionButtonType.PRIMARY}
                  label={techBotDataSources.updateButton}
                  icon={<IconUpdate />}
                  onPress={handleProcessWebsite}
                  disabled={isLoading}
                />
              ) : website.status === ResourceLinkStatus.PROCESSING ? (
                <ActionButton
                  size={ActionButtonSize.SMALL}
                  buttonType={ActionButtonType.PRIMARY}
                  label={techBotDataSources.updatingStatus}
                  loading
                  disabled
                />
              ) : website.status === ResourceLinkStatus.FAILED ? (
                <ActionButton
                  size={ActionButtonSize.SMALL}
                  buttonType={ActionButtonType.WARNING}
                  label={techBotDataSources.failedStatus}
                  icon={<IconFailed />}
                />
              ) : (
                '-'
              )
            ) : (
              '-'
            )}
          </td>
          <td className={cellMore}>
            {'bot_id' in website ? (
              website.status === ResourceLinkStatus.FAILED ? (
                <div className={buttonsWrapper}>
                  <div className={buttonIconReload} onClick={handleProcessWebsite}>
                    <IconReload />
                  </div>
                  <div className={buttonIcon} onClick={() => onDelete(website)}>
                    <IconDelete />
                  </div>
                </div>
              ) : (
                <div className={buttonIcon} onClick={() => onDelete(website)}>
                  <IconDelete />
                </div>
              )
            ) : (
              <div className={buttonIcon}>
                <IconLoader fillColor="#686868" />
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export default FileTr
