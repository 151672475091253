import { Box, List, ListItem, ListItemAvatar, Typography } from '@mui/material'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import CustomBreadcrumbs from 'components/breadcrumbs/Breadcrumbs'
import SmallFolderIcon from 'assets/icons/smallFolderIcon'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { messages } from 'constants/messages'

interface IProps {
  folders: { id: string; name: string }[]
  onClose?: () => void
}

const FoldersList = ({ folders, onClose }: IProps) => {
  const { integrations } = messages
  const { breadcrumbs, handleSetBreadcrumbs } = useBreadcrumbs([{ folderName: 'Google Drive', path: '' }])

  return (
    <Box>
      <Typography
        sx={{ marginBottom: '24px', fontSize: '16px', fontWeight: 'bold', lineHeight: '22px', color: '#353535' }}
      >
        {integrations.googleDrive.chooseFolder}
      </Typography>
      <Typography
        sx={{ marginBottom: '24px', fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#777777' }}
      >
        {integrations.googleDrive.tip}
      </Typography>
      <List
        sx={{
          maxHeight: '300px',
          overflowY: 'auto',
          marginBottom: '24px',
          padding: '0px',
          border: '1px solid #DCDCDC',
          borderRadius: '8px',
        }}
      >
        <ListItem
          sx={{
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <CustomBreadcrumbs options={breadcrumbs} handleSetBreadcrumbs={handleSetBreadcrumbs} />
        </ListItem>
        {folders.map((folder) => (
          <ListItem
            key={folder.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid #DCDCDC',
              borderBottom: '1px solid #DCDCDC',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',

                '.action-box': {
                  visibility: 'visible',
                  opacity: 1,
                },
              },
              '&:last-child': {
                borderBottom: 'none',
                borderBottomRightRadius: '8px',
                borderBottomLeftRadius: '8px',
              },
            }}
            // onClick={() => {
            //   setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, { folderName: folder.name, path: folder.id }])
            // }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '48px',
                  maxWidth: '48px',
                  minWidth: '48px',
                  height: '48px',
                  marginRight: '16px',
                  borderRadius: '8px',
                  backgroundColor: '#D9D9D9',
                }}
              >
                <SmallFolderIcon />
              </ListItemAvatar>
              {folder.name}
            </Box>
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '12px' }}>
        <ActionButton
          label={integrations.googleDrive.buttons.cancel}
          buttonType={ActionButtonType.SECONDARY}
          size={ActionButtonSize.MEDIUM}
          onPress={onClose}
        />
        <ActionButton
          label={integrations.googleDrive.buttons.download}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          onPress={() => {}}
        />
      </Box>
    </Box>
  )
}

export default FoldersList
