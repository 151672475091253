import { Box, Divider, Typography } from '@mui/material'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { messages } from 'constants/messages'
import connectGoogleDriveBG from 'assets/images/connectGoogleDriveBG.png'
import { api } from 'redux/rtkQuery'
import { useSelector } from 'react-redux'
import { botSelector } from 'redux/bot/slice'
import { IntegrationStatus } from 'types/api'
import CustomLoader from 'components/customLoader/CustomLoader'
import FoldersList from './FoldersList'
import { toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'

interface IProps {
  onClose?: () => void
}

const ConnectGoogleDriveModal = ({ onClose }: IProps) => {
  const { integrations } = messages
  const bot = useSelector(botSelector)

  const { data, isLoading } = api.useGetIntegrationDetailsQuery(
    { botId: bot.id },
    { skip: !bot.id, refetchOnMountOrArgChange: true }
  )

  const [triggerAuthGoogle] = api.useLazyAuthGoogleQuery()
  const [triggerSyncGoogle, { isLoading: sincLoading }] = api.useLazySyncGoogleQuery()
  const [triggerListFoldersGoogle, { data: folders, isLoading: foldersLoading }] = api.useLazyListFoldersGoogleQuery()

  const handleConnectGoogleDrive = async () => {
    try {
      const { error } = await triggerAuthGoogle({})

      if (error && 'data' in error) {
        window.open(error.data as string, '_blank')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleSyncGoogleDrive = async () => {
    try {
      const res = await triggerSyncGoogle({})

      if (res?.status === 'fulfilled') {
        toastFuncSuccess(integrations.googleDrive.googleDriveSynced)
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleListFoldersGoogleDrive = async () => {
    try {
      await triggerListFoldersGoogle({})
    } catch (error) {
      console.error('error', error)
    }
  }

  if (isLoading) {
    return <CustomLoader />
  }

  if (folders?.length) {
    return <FoldersList folders={folders} onClose={onClose} />
  }

  return (
    <Box>
      <Typography
        sx={{ marginBottom: '24px', fontSize: '16px', fontWeight: 'bold', lineHeight: '22px', color: '#353535' }}
      >
        {integrations.googleDrive.connectGoogleDrive}
      </Typography>
      <Typography
        sx={{ marginBottom: '24px', fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#777777' }}
      >
        {integrations.googleDrive.steps}
      </Typography>
      <img alt="background" loading="lazy" src={connectGoogleDriveBG} />
      <Typography
        sx={{
          marginTop: '24px',
          marginBottom: '8px',
          fontSize: '16px',
          fontWeight: 'bold',
          lineHeight: '22px',
          color: '#353535',
        }}
      >
        {integrations.googleDrive.aboutTheIntegration}
      </Typography>
      <Typography
        sx={{ marginBottom: '24px', fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#777777' }}
      >
        {integrations.googleDrive.descriptionAboutTheIntegration}
      </Typography>

      <Divider style={{ marginBottom: 24 }} />

      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '12px' }}>
        {IntegrationStatus.READY_TO_INSTALL === data?.google_drive?.status && (
          <ActionButton
            label={integrations.googleDrive.buttons.connect}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            onPress={handleConnectGoogleDrive}
          />
        )}

        {IntegrationStatus.INSTALLED === data?.google_drive?.status && (
          <ActionButton
            label={integrations.googleDrive.buttons.sync}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            loading={sincLoading}
            onPress={handleSyncGoogleDrive}
          />
        )}

        {IntegrationStatus.INSTALLED === data?.google_drive?.status && (
          <ActionButton
            label={integrations.googleDrive.buttons.list}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            loading={foldersLoading}
            onPress={handleListFoldersGoogleDrive}
          />
        )}
      </Box>
    </Box>
  )
}

export default ConnectGoogleDriveModal
