const IconUpdate = () => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.87179 10C1.53501 10 1.24996 9.88333 1.01663 9.65C0.783293 9.41667 0.666626 9.13161 0.666626 8.79483V1.20517C0.666626 0.868389 0.783293 0.583333 1.01663 0.35C1.24996 0.116667 1.53501 0 1.87179 0H4.91013V1H1.87179C1.82046 1 1.77346 1.02139 1.73079 1.06417C1.68801 1.10683 1.66663 1.15383 1.66663 1.20517V8.79483C1.66663 8.84617 1.68801 8.89317 1.73079 8.93583C1.77346 8.97861 1.82046 9 1.87179 9H12.1281C12.1795 9 12.2265 8.97861 12.2691 8.93583C12.3119 8.89317 12.3333 8.84617 12.3333 8.79483V1.20517C12.3333 1.15383 12.3119 1.10683 12.2691 1.06417C12.2265 1.02139 12.1795 1 12.1281 1H9.08979V0H12.1281C12.4649 0 12.75 0.116667 12.9833 0.35C13.2166 0.583333 13.3333 0.868389 13.3333 1.20517V8.79483C13.3333 9.13161 13.2166 9.41667 12.9833 9.65C12.75 9.88333 12.4649 10 12.1281 10H1.87179ZM6.99996 7.1L3.89746 3.9975L4.59996 3.29483L6.49996 5.19483V0H7.49996V5.19483L9.39996 3.29483L10.1025 3.9975L6.99996 7.1Z"
      fill="white"
    />
  </svg>
)

export default IconUpdate
