import { FC, useEffect } from 'react'
import styles from './TechBotDataSources.module.scss'
import { InfoModal } from './InfoModal'
import { messages } from 'constants/messages'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'
import DataSourcesStatus from '../dataSourcesStatus/DataSourcesStatus'
import { findLatestDate } from 'helpers/findLatestDate'
import dayjs from 'dayjs'
import { botActions } from 'redux/bot/slice'
import { useDispatch } from 'react-redux'
import TechBotDataSourcesNavigation from './components/techBotDataSourcesNavigation/TechBotDataSourcesNavigation'
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { DATA_SOURCES_URL, DATA_SOURCES_WEBSITES_URL } from 'utils/constant'
import { DataSourceDetail } from './components/dataSourceDetail/DataSourceDetail'
import { Box } from '@mui/material'
import PageHeader from 'components/pageHeader/PageHeader'

const {
  container,
  formStyles,
  navigationContainer,
  dataSourcesInfoContainer,
  dataSourcesInfoDescription,
  dataSourcesInfoTitle,
  dataSourcesDetailsContainer,
} = styles

const { techBotDataSources, dataSourcesStatus } = messages.techbots

const TechBotDataSources: FC = () => {
  const location = useLocation()?.pathname
  const navigate = useNavigate()

  useEffect(() => {
    if (location === DATA_SOURCES_URL) {
      navigate(DATA_SOURCES_WEBSITES_URL)
    }
  }, [location, navigate])

  const dispatch = useDispatch()
  const user = useAppSelector((state: ReduxState) => state.user)

  const companyId = user?.company_id

  const { data, isLoading: isGettingBots } = api.useGetCompanyBotQuery({ companyId: companyId }, { skip: !user })

  const botId = data?.id || ''

  const { data: dataSourcesData, isLoading: isGettingDataSources } = api.useGetSourceChangesQuery(
    { botId: botId! },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )
  const anySourceIsProcessing = dataSourcesData?.some((source) => source.processing) || false
  const shouldRebuildKnowledge = dataSourcesData?.some((source) => source.type) || false

  const { data: countResourcesData, isLoading: isGettingCount } = api.useGetCountResourcesQuery(
    { id: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const lastConversationsSyncAt = data?.last_conversations_sync_at
  const lastUrlsSyncAt = data?.last_urls_sync_at
  const lastKnowledgeSyncAt = data?.last_knowledge_sync_at
  const lastImageSyncAt = data?.last_images_sync_at

  const datesStrings = [lastConversationsSyncAt, lastUrlsSyncAt, lastKnowledgeSyncAt, lastImageSyncAt]

  const latestDate = findLatestDate(datesStrings)
  const latestFormattedDate = dayjs(latestDate).format('MM/DD/YY')

  useEffect(() => {
    if (data) {
      dispatch(botActions.setBot({ bot: data }))
    }
  }, [dispatch, data])

  return (
    <>
      {(isGettingBots || isGettingDataSources || isGettingCount) && <CustomLoader />}
      <InfoModal />
      <Box className={container}>
        <PageHeader title={dataSourcesStatus.title} isDataSourcesStatus>
          <DataSourcesStatus
            isRetraining={anySourceIsProcessing}
            shouldRebuildKnowledge={shouldRebuildKnowledge}
            updatedAt={latestFormattedDate}
          />
        </PageHeader>
        <div className={formStyles}>
          <div className={dataSourcesInfoContainer}>
            <div className={dataSourcesInfoDescription}>
              <p className={dataSourcesInfoTitle}>{techBotDataSources.dataSourcesSummaryTitle}</p>
              <p>{techBotDataSources.dataSourcesSummaryDescription}</p>
            </div>
            <div className={dataSourcesDetailsContainer}>
              <DataSourceDetail
                totalCount={(countResourcesData?.links.added ?? 0) + (countResourcesData?.links.crawled ?? 0)}
                name={techBotDataSources.links}
                added={countResourcesData?.links.added}
                isLinks
              />
              <DataSourceDetail
                totalCount={(countResourcesData?.files.added ?? 0) + (countResourcesData?.files.crawled ?? 0)}
                name={techBotDataSources.files}
                added={countResourcesData?.files.added}
                crawled={countResourcesData?.files.crawled}
              />
              <DataSourceDetail
                totalCount={countResourcesData?.snippets.total ?? 0}
                name={techBotDataSources.snippets}
                synced={countResourcesData?.snippets.synced}
              />
              <DataSourceDetail
                totalCount={(countResourcesData?.images.added ?? 0) + (countResourcesData?.images.crawled ?? 0)}
                name={techBotDataSources.images}
                added={countResourcesData?.images.added}
                crawled={countResourcesData?.images.crawled}
              />
            </div>
          </div>
          <div className={navigationContainer}>
            <TechBotDataSourcesNavigation />
          </div>
          <Outlet />
        </div>
      </Box>
    </>
  )
}

export default TechBotDataSources
