import { Dialog } from '@mui/material'

import style from './ChangeViewerModal.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { api } from 'redux/rtkQuery'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import { messages } from 'constants/messages'
import { TeamManagementUserRoles } from 'types/api'
const { wrapper, title, description } = style

interface IProps {
  isOpen: boolean
  emails: string[]
  handleVisible: () => void
  isInviteUsersToCompanyLoading: boolean
  onHandleInviteMemberModalClose: () => void
}

const { teamManagement, modals } = messages
const { changeViewerModal } = modals

const ChangeViewerModal = ({
  isOpen,
  emails,
  handleVisible,
  isInviteUsersToCompanyLoading,
  onHandleInviteMemberModalClose,
}: IProps) => {
  const emailsList = emails.join(', ')
  const [getUserByEmail] = api.useLazyGetUserByEmailQuery()
  const [updateUserRole, { isLoading: updateRoleLoading }] = api.useUpdateUserRoleMutation()

  const onEditRole = async () => {
    try {
      const users = await Promise.all(emails.map((email) => getUserByEmail({ email }).unwrap()))
      const mappedUsers = users.map((user) => ({
        userId: user.id,
        role: TeamManagementUserRoles.EXPERT,
      }))

      await Promise.all(mappedUsers.map((user) => updateUserRole(user).unwrap()))

      toastFuncNotification(teamManagement.roleUpdated)
    } catch {
      toastFuncError(teamManagement.toastError)
    } finally {
      handleVisible()

      if (!isInviteUsersToCompanyLoading) {
        onHandleInviteMemberModalClose()
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleVisible}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '291px',
          padding: '24px',
          borderRadius: '24px',
        },
      }}
    >
      <div className={wrapper}>
        <p className={title}>{changeViewerModal.title}</p>
        <p className={description}>
          {emails.length > 1
            ? changeViewerModal.firstPart.replace('{emailsList}', emailsList)
            : changeViewerModal.secondPart.replace('{emailsList}', emailsList)}
        </p>
        <ActionButton
          label={changeViewerModal.submit}
          loading={updateRoleLoading}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          onPress={onEditRole}
        />
        <ActionButton
          label={changeViewerModal.cancel}
          buttonType={ActionButtonType.SECONDARY}
          size={ActionButtonSize.MEDIUM}
          onPress={handleVisible}
        />
      </div>
    </Dialog>
  )
}

export default ChangeViewerModal
