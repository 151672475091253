import { FC, ReactNode, useState } from 'react'
import styles from './PageHeader.module.scss'
import IconClose from 'assets/icons/iconClose'
import { PROFILE_URL, ROOT_URL, SUBSCRIPTION_URL } from 'utils/constant'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import UserModal from 'components/userModal/UserModal'
import IconAvatar from 'assets/icons/iconAvatar'
import IconDefaultAvatar from 'assets/icons/iconDefaultAvatar'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'
import IconSubscription from 'assets/icons/iconSubscription'

interface iPageHeader {
  title?: string
  closeButton?: boolean
  backRoute?: string
  extraCloseButtonAction?: () => void
  borderLine?: boolean
  children?: ReactNode
  hideUserMenu?: boolean
  isDataSourcesStatus?: boolean
}

const { container, row, titleStyles, iconStyles, borderBottom, avatar, userMenu, avatarImage, statusWrapper } = styles

const PageHeader: FC<iPageHeader> = ({
  title,
  closeButton,
  backRoute = ROOT_URL,
  extraCloseButtonAction,
  borderLine,
  hideUserMenu,
  children,
  isDataSourcesStatus,
}) => {
  const navigate = useNavigate()
  const [isUserModalVisible, setIsUserModalVisible] = useState(false)
  const userId = useAppSelector((state: ReduxState) => state.user.id)
  const { data: user } = api.useMeQuery(undefined, { skip: !userId })

  const onHandleBack = (): void => {
    if (closeButton) {
      if (extraCloseButtonAction) {
        extraCloseButtonAction()
      } else {
        navigate(backRoute)
      }
    }
  }

  return (
    <header className={`${container} ${borderLine ? borderBottom : ''}`}>
      {title && <div className={titleStyles}>{title}</div>}
      {isDataSourcesStatus ? <div className={statusWrapper}>{children}</div> : null}
      <div className={row}>
        {!isDataSourcesStatus ? children : null}
        {!hideUserMenu && (
          <div className={userMenu}>
            <button
              className={avatar}
              onClick={() => {
                setIsUserModalVisible(!isUserModalVisible)
              }}
            >
              {user?.avatar_url ? (
                <img className={avatarImage} src={user.avatar_url} alt="Avatar" />
              ) : (
                <IconDefaultAvatar />
              )}
            </button>
            <UserModal
              isVisible={isUserModalVisible}
              links={[
                {
                  text: 'Profile',
                  route: PROFILE_URL,
                  icon: IconAvatar,
                },
                {
                  text: 'Subscription',
                  route: SUBSCRIPTION_URL,
                  icon: IconSubscription,
                },
                // {
                //   text: 'Notifications',
                //   route: ROOT_URL,
                //   icon: IconNotification,
                // },
                // {
                //   text: 'Help Center',
                //   route: ROOT_URL,
                //   icon: HelpIcon,
                // },
              ]}
              onClose={() => setIsUserModalVisible(false)}
            />
          </div>
        )}
        {closeButton && (
          <div className={iconStyles} onClick={onHandleBack}>
            <IconClose />
          </div>
        )}
      </div>
    </header>
  )
}

export default PageHeader
