import { FC, useRef, MutableRefObject, MouseEvent } from 'react'
import styles from './UserModal.module.scss'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import LogoutIcon from 'assets/icons/logoutIcon'
import { LOGOUT } from 'utils/constant'
import { useClickOutside } from 'helpers/useClickOutside'
import { api } from 'redux/rtkQuery'

interface ILink {
  text: string
  route: string
  icon: FC
}
interface IUserModal {
  isVisible: boolean
  links: ILink[]
  onClose: () => void
}

const { linksGroup, link, activeLink, container, userName, companyName, separator } = styles

const UserModal: FC<IUserModal> = ({ isVisible, links, onClose }) => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state: ReduxState) => state.user.id)
  const { data: user } = api.useMeQuery(undefined, { skip: !userId })

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  useClickOutside(ref, (e: MouseEvent<HTMLButtonElement>) => onHandleClose(e), true)

  const onHandleClose = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    onClose()
  }

  const renderlinks = (): JSX.Element => (
    <div className={linksGroup}>
      {links.map((button: ILink, index: number) => {
        const Icon: FC = button.icon
        return (
          <NavLink
            className={({ isActive }) => (isActive ? `${activeLink} ${link}` : link)}
            to={button.route}
            key={`link_${button.text}`}
          >
            <Icon />
            <span>{button.text}</span>
          </NavLink>
        )
      })}
    </div>
  )

  return isVisible ? (
    <div className={container} ref={ref}>
      <p className={userName}>{user?.name}</p>
      <p className={companyName}>{user?.company_name}</p>
      <div className={separator}></div>
      {renderlinks()}
      <div className={separator}></div>
      <div className={linksGroup}>
        <button
          className={link}
          onClick={() => {
            dispatch({ type: LOGOUT })
          }}
        >
          <LogoutIcon />
          <span>Log Out</span>
        </button>
      </div>
    </div>
  ) : null
}

export default UserModal
