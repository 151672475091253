import { FC, useState, useEffect } from 'react'
import styles from './PortalPreview.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import CustomInput from 'components/customInput/CustomInput'
import { messages } from 'constants/messages'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'
import CustomLoader from 'components/customLoader/CustomLoader'
import PortalPreviewIcon from 'assets/icons/portalPreviewIcon'
import { Divider, Switch, Typography } from '@mui/material'
import { SXObject } from '../techBotDetails/constants'
import { Note } from 'components/noteComponent/Note'
import { toastFuncSuccess, toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { Box } from '@mui/system'
import PortalViewersModal from './PortalViewersModal'

const {
  portalPreviewContainer,
  portalPreviwContent,
  copyContainer,
  title,
  description,
  buttonContainer,
  preview,
  authContainer,
  authLabel,
  authDescription,
  authDescriptionContainer,
} = styles

const { portalPreview } = messages.portal

const PortalPreview: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const user = useAppSelector((state: ReduxState) => state.user)
  const { data: bot, isLoading: isGettingBotData } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = bot?.id || ''

  const [botAuth, setBotAuth] = useState({ portalAuth: bot?.portal_auth })

  const { data: countViewers } = api.useGetCountViewersQuery()

  useEffect(() => {
    if (bot?.portal_auth !== undefined) {
      setBotAuth({ portalAuth: bot.portal_auth })
    }
  }, [bot?.portal_auth])

  const { data: urls, isLoading: isGettingUrls } = api.useGetUrlsQuery(
    { id: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const { data, isLoading: isGettingBot } = api.useGetBotByIdQuery(
    { id: botId as string },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const handleOpenInNewTab = (): void => {
    window.open(urls?.portal_url, '_blank')
  }
  const [updateBotById] = api.useUpdateBotByIdMutation()

  const { data: dataSourcesData, isLoading: isGettingDataSources } = api.useGetSourceChangesQuery(
    { botId: botId! },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const anySourceIsProcessing = dataSourcesData?.some((source) => source.processing) || false

  const shouldRebuildKnowledge = dataSourcesData?.some((source) => source.type) || false

  let status = 'ready'

  if (anySourceIsProcessing) {
    status = 'updating'
  } else if (shouldRebuildKnowledge && !anySourceIsProcessing) {
    status = 'ready to update'
  } else if (!anySourceIsProcessing && !shouldRebuildKnowledge) {
    status = 'ready'
  }

  const handleAuth = async (checked: boolean) => {
    try {
      setBotAuth({ portalAuth: checked })

      await updateBotById({ id: botId, body: { portal_auth: checked } }).unwrap()
      toastFuncSuccess(portalPreview.saveChangesSuccess)
    } catch (error) {
      toastFuncError(portalPreview.saveDataError)
    }
  }

  if (isGettingBotData || isGettingBot || isGettingDataSources || isGettingUrls) return <CustomLoader />

  return (
    <>
      <div className={portalPreviewContainer}>
        <div className={portalPreviwContent}>
          <div className={copyContainer}>
            <h4 className={title}>{portalPreview.title.replace('{status}', status)}</h4>
            <p className={description}>{portalPreview.description}</p>
            <CustomInput
              value={urls?.portal_url || ''}
              disabled
              onChangeValue={() => {}}
              placeholder={portalPreview.linkPlaceholder}
            />
            <div className={buttonContainer}>
              <ActionButton
                label={portalPreview.openInNewTab}
                onPress={handleOpenInNewTab}
                buttonType={ActionButtonType.PRIMARY}
                size={ActionButtonSize.MEDIUM}
              />
            </div>
            <Divider sx={{ marginTop: '20px' }} />
            <Typography
              sx={{
                fontFamily: 'Manrope',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '22px',
                letterSpacing: '-0.1px',
                color: '#191919',
                marginTop: '20px',
              }}
            >
              {portalPreview.manageAccessToPortal}
            </Typography>
            <div className={authContainer}>
              <p className={authLabel}>{portalPreview.authProtection}</p>
              <div className={authDescriptionContainer}>
                <p className={authDescription}>{portalPreview.authProtectionDescription}</p>
                <Switch
                  checked={!!botAuth.portalAuth}
                  sx={SXObject}
                  onChange={async (e, checked) => handleAuth(checked)}
                />
              </div>
            </div>
            {!!botAuth.portalAuth ? (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '488px',
                  border: '1px solid #DCDCDC',
                  borderRadius: '12px',
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ width: '100%', maxWidth: '306px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Manrope',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '22px',
                      color: '#353535',
                    }}
                  >
                    {countViewers || 0} {portalPreview.usersCanAccessThePortal}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      lineHeight: '22px',
                      letterSpacing: '-0.1px',
                      color: '#777777',
                    }}
                  >
                    {portalPreview.selectIndividualEmail}
                  </Typography>
                </Box>
                <ActionButton
                  label={portalPreview.manageAccess}
                  buttonType={ActionButtonType.PRIMARY}
                  size={ActionButtonSize.MEDIUM}
                  onPress={() => setIsModalOpen(true)}
                />
              </Box>
            ) : (
              <Note title={portalPreview.noteTitle} description={portalPreview.noteDescription} />
            )}
          </div>
        </div>
        <div className={preview}>
          <PortalPreviewIcon
            initialMessage={
              data?.portal_initial_message
                ? data?.portal_initial_message.length > 60
                  ? `${data?.portal_initial_message.slice(0, 60)}...`
                  : data?.portal_initial_message
                : undefined
            }
            subMessage={
              data?.portal_sub_message
                ? data?.portal_sub_message.length > 69
                  ? `${data?.portal_sub_message.slice(0, 69)}...`
                  : data?.portal_sub_message
                : undefined
            }
            primaryFillColor={data?.portal_primary_color || undefined}
            image={data?.portal_logo_url || undefined}
            isPreview={true}
          />
        </div>
        <PortalViewersModal open={isModalOpen} onClose={() => setIsModalOpen(false)} count={countViewers || 0} />
      </div>
    </>
  )
}

export default PortalPreview
