import { ParsedResourceLink } from 'types/api'
import styles from './DataTable.module.scss'
import { messages } from 'constants/messages'
import { Box } from '@mui/system'
import { themeColors } from 'styles/colors'
import FileTr from './FileTr'

const { table, emptyRow } = styles

interface IProps {
  data: ParsedResourceLink[] | null | undefined
  onRemove: (item: ParsedResourceLink) => unknown
}

const DataTable = ({ data, onRemove }: IProps) => {
  const { techBotDataSources } = messages.techbots

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderColor: themeColors.lightGray,
          borderRadius: '8px',
        }}
      >
        <table className={table}>
          <thead>
            <tr>
              <th>{techBotDataSources.link}</th>
              <th>{techBotDataSources.pagesCrawled}</th>
              <th>{techBotDataSources.imagesWebsite}</th>
              <th>{techBotDataSources.pdf}</th>
              <th>{techBotDataSources.lastUpdate}</th>
              <th>{techBotDataSources.updateSource}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              data?.map((website) => <FileTr key={website.id} website={website} onDelete={() => onRemove(website)} />)
            ) : (
              <tr className={emptyRow}>
                <td colSpan={7}>{techBotDataSources.noWebsites}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </>
  )
}

export default DataTable
