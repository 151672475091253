import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BotStatus, TechBot } from 'types/api'

const initialState: TechBot = {
  id: '',
  name: '',
  title: '',
  code: '',
  logo_url: '',
  handoffEmail: '',
  handoffType: '',
  status: BotStatus.OFFLINE,
  welcome_message: '',
  offline_message: '',
  widget_message: '',
  color_primary: '',
  color_secondary: '',
  authorized_url_patterns: [],
  company_id: '',
  created_at: '',
  updated_at: '',
  last_build_at: '',
  company_website_domain: '',
  isInstalled: false,
  isUpdating: false,
  include_grounding: false,
  portal_prefix: '',
  portal_primary_color: '',
  portal_secondary_color: '',
  portal_logo_url: '',
  portal_header_image_url: '',
  portal_initial_message: '',
  portal_sub_message: '',
  last_urls_sync_at: '',
  last_knowledge_sync_at: '',
  last_conversations_sync_at: '',
  last_images_sync_at: '',
  isWidgetEnabled: false,
  portal_auth: false,
}

const botSlice = createSlice({
  name: 'BOT',
  initialState,
  reducers: {
    setBot: (state, action: PayloadAction<{ bot: TechBot }>) => {
      return { ...action.payload.bot }
    },
  },
})

export const botActions = botSlice.actions

// After switching to slices completely, the : any type needs to be removed
export const botSelector = (state: any) => state.bot

export default botSlice.reducer
