import { IUploadedFile, IUploadedFolder } from 'types/api'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material'
import { api } from 'redux/rtkQuery'
import { useSelector } from 'react-redux'
import { botSelector } from 'redux/bot/slice'
import SmallFolderIcon from 'assets/icons/smallFolderIcon'
import CustomBreadcrumbs from 'components/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useCallback } from 'react'
import { Loader } from 'components/Loader'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { isFolder } from 'helpers/isFolder'
import { Breadcrumbs } from './Files'
import IconArrowBack from 'assets/icons/iconArrowBack'
import IconClose from 'assets/icons/iconClose'
import { messages } from 'constants/messages'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'

export type MoveToModalProps = {
  currentPath: Breadcrumbs
  selectedEntry: IUploadedFolder | IUploadedFile
  setSelectedEntry: React.Dispatch<React.SetStateAction<IUploadedFile | IUploadedFolder | null>>
}

const { files: text } = messages

export const MoveTo = ({ currentPath, selectedEntry, setSelectedEntry }: MoveToModalProps) => {
  const bot = useSelector(botSelector)
  const { pathOfBreadcrumbs, breadcrumbs, handleSetBreadcrumbs, setBreadcrumbs } = useBreadcrumbs()

  const {
    data,
    isLoading: isGettingInitialFolder,
    isFetching,
  } = api.useGetFoldersForMoveToQuery({
    botId: bot.id,
    folderPath: pathOfBreadcrumbs,
  })

  const [moveFolder, { isLoading: isMovingFolder }] = api.useMoveFolderMutation()
  const [moveFile, { isLoading: isMovingFile }] = api.useMoveFileMutation()

  const disabled = isMovingFile || isMovingFolder

  const handleMoveFolder = useCallback(
    async (folderPath?: string) => {
      try {
        const currentFolderPath = currentPath.map((crumb) => crumb.path).join('/') + '/' + selectedEntry.id
        const basePath = breadcrumbs.map((crumb) => crumb.path).join('/')
        const targetFolderPath = folderPath ? `${basePath}/${folderPath}` : basePath
        const botId = bot.id

        if (targetFolderPath.includes(currentFolderPath)) {
          toastFuncError(text.cantMoveFolderInChild)
          return
        }

        const res = await moveFolder({
          botId,
          currentFolderPath,
          targetFolderPath,
        }).unwrap()

        if (res) {
          setSelectedEntry(null)
        }
      } catch (error) {}
    },
    [bot.id, breadcrumbs, currentPath, moveFolder, selectedEntry.id, setSelectedEntry]
  )

  const handleMoveFile = useCallback(
    async (folderPath?: string) => {
      try {
        const currentFolderPath = currentPath.map((crumb) => crumb.path).join('/')
        const basePath = breadcrumbs.map((crumb) => crumb.path).join('/')
        const targetFolderPath = folderPath ? `${basePath}/${folderPath}` : basePath
        const fileId = selectedEntry.id
        const botId = bot.id

        const res = await moveFile({
          botId,
          fileId,
          currentFolderPath,
          targetFolderPath,
        }).unwrap()

        if (res) {
          setSelectedEntry(null)
        }
      } catch (error) {}
    },
    [bot.id, breadcrumbs, currentPath, moveFile, selectedEntry.id, setSelectedEntry]
  )

  const handleMoveTo = useCallback(
    async (folderPath?: string) => {
      if (isFolder(selectedEntry)) {
        handleMoveFolder(folderPath)
      } else {
        handleMoveFile(folderPath)
      }
    },
    [handleMoveFile, handleMoveFolder, selectedEntry]
  )

  return (
    <Dialog
      open={!!selectedEntry}
      onClose={() => setSelectedEntry(null)}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          padding: '14px 14px 8px 14px',
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>
            {text.move} {`"${selectedEntry.name}"`}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#777',
            }}
          >
            {text.currentFolder} {`"${currentPath[currentPath.length - 1].folderName}."`} {text.chooseNewDirectory}
          </Typography>
        </Box>
        <IconButton
          aria-label="arrowback"
          onClick={() => setSelectedEntry(null)}
          sx={{ position: 'relative', top: '-10px' }}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isGettingInitialFolder || isFetching ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <Loader />
          </Box>
        ) : !!data?.length ? (
          <List
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '0px',
              border: '1px solid #DCDCDC',
              borderRadius: '8px',
            }}
          >
            <ListItem
              sx={{
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                backgroundColor: '#f5f5f5',
              }}
            >
              <CustomBreadcrumbs options={breadcrumbs} handleSetBreadcrumbs={handleSetBreadcrumbs} />
            </ListItem>
            {data?.map((folder) => (
              <ListItem
                key={folder.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderTop: '1px solid #DCDCDC',
                  borderBottom: '1px solid #DCDCDC',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',

                    '.action-box': {
                      visibility: 'visible',
                      opacity: 1,
                    },
                  },
                  '&:last-child': {
                    borderBottom: 'none',
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  },
                }}
                onClick={() => {
                  setBreadcrumbs((prevBreadcrumbs) => [
                    ...prevBreadcrumbs,
                    { folderName: folder.name, path: folder.id },
                  ])
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '48px',
                      maxWidth: '48px',
                      minWidth: '48px',
                      height: '48px',
                      marginRight: '16px',
                      borderRadius: '8px',
                      backgroundColor: '#D9D9D9',
                    }}
                  >
                    <SmallFolderIcon />
                  </ListItemAvatar>
                  {folder.name}
                </Box>
                <Box
                  className="action-box"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden',
                    opacity: 0,
                    transition: 'visibility 0s, opacity 0.2s ease-in-out',
                  }}
                >
                  <ActionButton
                    label={text.move}
                    buttonType={ActionButtonType.PRIMARY}
                    size={ActionButtonSize.MEDIUM}
                    onPress={() => handleMoveTo(folder.id)}
                  />
                  <Box
                    sx={{
                      marginLeft: '16px',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    <IconArrowBack />
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <List
            sx={{
              padding: '0px',
              border: '1px solid #DCDCDC',
              borderRadius: '8px',
            }}
          >
            <ListItem
              sx={{
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                backgroundColor: '#f5f5f5',
              }}
            >
              <CustomBreadcrumbs options={breadcrumbs} handleSetBreadcrumbs={handleSetBreadcrumbs} />
            </ListItem>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
              }}
            >
              <Typography>{text.noFolders}</Typography>
            </Box>
          </List>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px 24px',
        }}
      >
        <ActionButton
          label={text.buttons.cancel}
          buttonType={ActionButtonType.TRANSPARENT}
          size={ActionButtonSize.MEDIUM}
          loading={disabled}
          onPress={() => setSelectedEntry(null)}
        />
        <ActionButton
          label={isFolder(selectedEntry) ? text.moveFolder : text.moveFile}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          onPress={handleMoveTo}
          disabled={data?.length === 0}
        />
      </DialogActions>
    </Dialog>
  )
}
