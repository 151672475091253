import { Note } from 'components/noteComponent/Note'
import styles from './Integrations.module.scss'
import IntegrationCard from './integrationCard/IntegrationCard'
import hubspotLogo from 'assets/images/hubspotLogo.png'
import zendeskLogo from 'assets/images/zendeskLogo.png'
import freshdeskLogo from 'assets/images/freshdeskLogo.png'
import { useAppSelector } from 'redux/hooks'
import { api } from 'redux/rtkQuery'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { messages } from 'constants/messages'
import { IntegrationStatus } from 'types/api'

const { integrationsContainer, integrationsHeader, integrationCardsContainer } = styles

const Integrations = () => {
  const { integrations } = messages
  const user = useAppSelector((state: ReduxState) => state.user)
  const { data: bot, isLoading: isGettingCompanyBot } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user?.company_id, refetchOnMountOrArgChange: true }
  )

  const botId = bot?.id || ''

  const { data, isLoading: isGettingIntegrationDetails } = api.useGetIntegrationDetailsQuery(
    { botId: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [deleteZendeskCredentials, { isLoading: isDeletingZendesk }] = api.useDeleteZendeskCredentialsMutation()

  const handleDelete = async () => {
    try {
      await deleteZendeskCredentials({ botId: botId }).unwrap()
    } catch {
      toastFuncError('Error deleting credentials')
    }
  }

  return (
    <div className={integrationsContainer}>
      <div className={integrationsHeader}>{integrations.header}</div>
      <Note title={integrations.title} description={integrations.description} />
      <div className={integrationCardsContainer}>
        <IntegrationCard
          logo={zendeskLogo}
          name={integrations.zendesk.title}
          description={integrations.zendesk.title}
          isAvaliable={data?.zendesk?.status === IntegrationStatus.READY_TO_INSTALL}
          isInstalled={data?.zendesk?.status === IntegrationStatus.INSTALLED}
          onDelete={() => handleDelete()}
        />
        <IntegrationCard
          logo={hubspotLogo}
          name={integrations.hubspot.title}
          description={integrations.hubspot.title}
        />
        <IntegrationCard
          logo={freshdeskLogo}
          name={integrations.freshdesk.title}
          description={integrations.freshdesk.title}
        />
      </div>
      {(isGettingCompanyBot || isGettingIntegrationDetails || isDeletingZendesk) && <CustomLoader />}
    </div>
  )
}

export default Integrations
