import { FC, SVGProps } from 'react'

interface IProps {
  fill?: string
}

const IconArrowDown: FC<SVGProps<SVGSVGElement>> = ({ fill = '#777777' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask
      id="mask0_113_2186"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect y="16" width="16" height="16" transform="rotate(-90 0 16)" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_113_2186)">
      <path
        d="M13.9577 5.80128L8.42176 11.3474C8.35937 11.4098 8.29356 11.4538 8.22432 11.4795C8.1551 11.5051 8.08032 11.5179 7.99997 11.5179C7.91963 11.5179 7.84485 11.5051 7.77562 11.4795C7.70639 11.4538 7.64058 11.4098 7.57819 11.3474L2.02562 5.79488C1.89998 5.66923 1.83716 5.51539 1.83716 5.33334C1.83716 5.1513 1.90554 4.9919 2.04229 4.85514C2.17477 4.72267 2.33075 4.65643 2.51022 4.65643C2.68971 4.65643 2.8457 4.72267 2.97817 4.85514L7.99997 9.87694L13.0282 4.84874C13.1538 4.7231 13.306 4.66028 13.4846 4.66028C13.6632 4.66028 13.8209 4.72865 13.9577 4.86541C14.0901 4.99788 14.1564 5.15385 14.1564 5.33334C14.1564 5.51283 14.0901 5.66881 13.9577 5.80128Z"
        fill={fill}
      />
    </g>
  </svg>
)

export default IconArrowDown
