import { IGetFilesData, TSearchData } from 'types/api'
import styles from './DataTable.module.scss'
import { messages } from 'constants/messages'
import { ChangeEvent } from 'react'
import { Box } from '@mui/system'
import FileTr from './FileTr'
import FolderTr from './FolderTr'
import { Breadcrumbs } from '../Files'
import { Loader } from 'components/Loader'
import { EmptyFolderView } from '../EmptyFolderView'
import PaginationComponent from 'components/pagination/PaginationComponent'
import { themeColors } from 'styles/colors'
import { filterSearchDataByFolderType } from 'helpers/filterSearchDataByFolderType'

const { table, cellMoreTh } = styles

interface IProps {
  data: IGetFilesData | null | undefined
  searchData?: TSearchData[]
  searchValue?: string
  onChangePage: (event: ChangeEvent<unknown>, value: number) => void
  setBreadcrumbs: (folderName: string, path: string) => void
  breadcrumbs: Breadcrumbs
  pathOfBreadcrumbs: string
  handleSetIsUploadFileModalOpen: () => void
  isFetching: boolean
}

const DataTable = ({
  data,
  searchData,
  searchValue,
  onChangePage,
  setBreadcrumbs,
  breadcrumbs,
  pathOfBreadcrumbs,
  handleSetIsUploadFileModalOpen,
  isFetching,
}: IProps) => {
  const { files: filesText } = messages
  const { searchFolders, searchFiles } = filterSearchDataByFolderType(searchData)

  if (isFetching) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Loader />
      </Box>
    )
  }

  if (!data?.files?.length && !data?.folders?.length) {
    return <EmptyFolderView handleSetIsUploadFileModalOpen={handleSetIsUploadFileModalOpen} />
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderColor: themeColors.lightGray,
          borderRadius: '8px',
        }}
      >
        <table className={table}>
          <thead>
            <tr>
              <th>{filesText.name}</th>
              <th>{filesText.addedDate}</th>
              <th>{filesText.type}</th>
              <th>{filesText.source}</th>
              <th>{filesText.status}</th>
              <th className={cellMoreTh}></th>
            </tr>
          </thead>
          <tbody>
            {!searchData?.length &&
              !!data?.folders?.length &&
              data.folders.map((folder) => (
                <FolderTr key={folder.id} folder={folder} setBreadcrumbs={setBreadcrumbs} breadcrumbs={breadcrumbs} />
              ))}
            {!searchData?.length &&
              !!data?.files?.length &&
              data.files.map((file) => (
                <FileTr key={file.id} file={file} breadcrumbs={breadcrumbs} pathOfBreadcrumbs={pathOfBreadcrumbs} />
              ))}
            {searchFolders.length > 0 &&
              searchFolders.map((folder) => (
                <FolderTr
                  key={folder.id}
                  folder={folder}
                  setBreadcrumbs={setBreadcrumbs}
                  breadcrumbs={breadcrumbs}
                  searchValue={searchValue}
                />
              ))}
            {searchFiles.length > 0 &&
              searchFiles.map((file) => (
                <FileTr
                  key={file.id}
                  file={file}
                  breadcrumbs={breadcrumbs}
                  pathOfBreadcrumbs={pathOfBreadcrumbs}
                  searchValue={searchValue}
                />
              ))}
          </tbody>
        </table>
      </Box>
      {data?.totalPages && data.totalPages > 1 && (
        <PaginationComponent totalPages={data.totalPages} page={data.page} handlePageChange={onChangePage} />
      )}
    </>
  )
}

export default DataTable
