import { api } from 'redux/rtkQuery'
import { useCallback, useState, useEffect, ChangeEvent } from 'react'
import { messages } from 'constants/messages'
import {
  toastFuncError,
  toastFuncNotification,
  toastFuncSuccess,
} from 'components/customToastContainer/CustomToastContainer'
import { isValidUrl } from 'helpers/validation'
import { ParsedResourceLink } from 'types/api'
import { handelShowError } from 'helpers/handelShowError'

const { techBotDataSources } = messages.techbots

export const useLinks = (botId: string) => {
  const [page, setPage] = useState(1)

  const initialCheckboxState = localStorage.getItem('includePDFs') === 'true'

  const [stateCheckbox, setStateCheckbox] = useState(initialCheckboxState)

  const { data: websites, isLoading: isLinksLoading } = api.useGetWebsitesQuery(
    { id: botId, page },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [addWebsite, { isLoading: isAddingWebsite }] = api.useAddWebsiteMutation()
  const [deleteWebsite] = api.useDeleteWebsiteMutation()

  const [addedLinks, setAddedLinks] = useState<ParsedResourceLink[] | undefined>(websites?.websites)

  const totalLinks = websites?.total || 0
  const totalPages = Math.ceil(totalLinks / (websites?.limit ?? 10))

  useEffect(() => {
    setAddedLinks(websites?.websites)
  }, [websites])

  const onHandleAddLink = useCallback(
    async (url: string) => {
      let newLinks = [...(addedLinks || [])]
      try {
        let parsedUrl = url.trim().toLowerCase()

        if (parsedUrl.includes('http://')) {
          parsedUrl = parsedUrl.replace('http://', 'https://')
        }

        if (parsedUrl === '') {
          toastFuncError(techBotDataSources.enterUrl)
          return
        }

        if (!isValidUrl(parsedUrl)) {
          toastFuncError(techBotDataSources.invalidUrl)
          return
        }

        // if (addedLinks?.find((link) => link.url === parsedUrl || link.url === parsedUrl.slice(0, -1))) {
        //   toastFuncError(techBotDataSources.existedUrl)
        //   return
        // }
        setAddedLinks([{ url: parsedUrl } as ParsedResourceLink, ...newLinks])

        await addWebsite({
          botId,
          url: parsedUrl,
          download_pdf: stateCheckbox,
        }).unwrap()
        toastFuncSuccess(techBotDataSources.addLinkSuccess)
      } catch {
        setAddedLinks((prevLinks) => prevLinks?.slice(1) || [])

        toastFuncError(techBotDataSources.addLinkError)
      }
    },
    [addWebsite, botId, addedLinks, stateCheckbox]
  )

  const handlePageChange = (event: ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage)
  }

  const onHandleRemoveLink = useCallback(
    async (link: ParsedResourceLink) => {
      const index = addedLinks?.findIndex((item) => item.url === link.url)

      if (index === -1 || index === undefined) {
        return
      }

      let newLinks = [...(addedLinks || [])]
      const swapedValue = newLinks[index]

      try {
        newLinks[index] = { url: link.url } as ParsedResourceLink
        setAddedLinks(newLinks)

        await deleteWebsite({
          websiteId: link.id,
          botId,
        }).unwrap()

        if (newLinks.length === 1 && page > 1) {
          handlePageChange(null, page - 1)
        }

        toastFuncNotification(techBotDataSources.deleteLink)
      } catch (error) {
        newLinks[index] = swapedValue
        setAddedLinks(newLinks)

        handelShowError(error)
      }
    },
    [addedLinks, botId, deleteWebsite, page]
  )

  return {
    onHandleAddLink,
    onHandleRemoveLink,
    websites,
    isLinksLoading,
    isAddingWebsite,
    addedLinks,
    handlePageChange,
    page,
    totalPages,
    stateCheckbox,
    setStateCheckbox,
  }
}
