import { IUploadedFile, IUploadedFolder, TSearchData } from 'types/api'

const isUploadedFolder = (file: TSearchData): file is IUploadedFolder => 'folder_type' in file
const isUploadedFile = (file: TSearchData): file is IUploadedFile => !('folder_type' in file)

export const filterSearchDataByFolderType = (searchData: TSearchData[] | undefined) => {
  const searchFolders: IUploadedFolder[] = searchData?.filter(isUploadedFolder) || []
  const searchFiles: IUploadedFile[] = searchData?.filter(isUploadedFile) || []

  return { searchFolders, searchFiles }
}
