import { FC } from 'react'

interface IProps {
  fillColor?: string
}

const IconCheck: FC<IProps> = ({ fillColor = '#909090' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <mask id="mask0_169_8591" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_169_8591)">
        <path
          d="M6.36646 11.7693L2.81006 8.21287L3.52288 7.50007L6.36646 10.3436L12.4767 4.2334L13.1895 4.9462L6.36646 11.7693Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default IconCheck
